import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import HomeFinancialMovements from './HomeFinancialMovement'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const FinancialMovement = () => {

    return (
        <div className="content">
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <BreadCrumb data={['Início', 'Financeiro', 'Movimentação de Banco/Caixa']}
                            path={['home', null, null]} />
                        <HomeFinancialMovements />
                    </Col>
                </Row>
            </Grid>
        </div>
    );
}

export default FinancialMovement