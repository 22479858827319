import React from "react";
import { Grid, Row } from "react-bootstrap";

import HomeProviders from './Providers'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const Providers = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb 
            data={['Início', 'Fornecedores']}
            path={['home', 'providers']}
          />
          <HomeProviders />
        </Row>
      </Grid>
    </div>
  )
}

export default Providers
