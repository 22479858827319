import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import HomeBillsToReceive from './BillsToReceive'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

import './styles.css'

const BillsToReceive = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <BreadCrumb 
              data={['Início', 'Contas a Receber']}
              path={['home', null]}
            />
            <HomeBillsToReceive />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default BillsToReceive