import React, { useEffect } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

import HomePaymentCondition from './PaymentCondition';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';

const PaymentCondition = () => {
  const pathName = localStorage.getItem('selectedPath');
  useEffect(() => {
    if (pathName === 'client/NovaVenda') {
      localStorage.setItem('selectedPath', 'client/NovaVenda');
    } else {
      localStorage.setItem('selectedPath', 'client/payment-conditions');
    }
  }, [pathName]);

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <BreadCrumb
              data={['Início', 'Cadastros', 'Vendas', 'Condição de Pagamento']}
              path={['home', null, null, null]}
            />
            <HomePaymentCondition />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default PaymentCondition;
