import React, { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useAuth } from 'contexts/auth';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { useEffect } from 'react';
import Vehicles from '../../../../repositories/Vehicles';

const defaultStyles = {
  control: (base) => ({
    ...base,
    width: '55rem',
    height: '35px',
    minHeight: '35px',
    marginTop: '1.5px',
    borderColor: '#E3E3E3',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    marginTop: '-7px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    marginTop: '-7px',
  }),
};

const defaultPlaceholder =
  'Pesquisa por placa, chassi, objeto de manutenção, série ou identificação';

export default function SelectVehicles({
  onChange,
  value,
  styles,
  placeholder = defaultPlaceholder,
  reloadTrigger,
  setReloadTrigger,
  disabled = false,
  ...rest
}) {
  const { companyId } = useAuth();
  const [currentInputKey, setCurrentInputKey] = useState(1);

  useEffect(() => {
    if (reloadTrigger) {
      setReloadTrigger(false);
      setCurrentInputKey(currentInputKey + 1);
    }
  }, [reloadTrigger]);

  function handleLabel(vehicle) {
    switch (true) {
      case vehicle.vehicle_plate === '' &&
        vehicle.vehicle_identification_serial !== '':
        return `${vehicle.vehicle_identification_serial} - ${vehicle.vehicle_model}`;
      default:
        return `${vehicle.vehicle_plate} - ${vehicle.vehicle_model}`;
    }
  }

  function handleValue(vehicle) {
    return vehicle.vehicle_id;
  }

  async function handleLoadVehicles(search, loadedOptions, { page }) {
    try {
      const res = await Vehicles.getAllByCompanyPaginated({
        company_id: companyId,
        page,
        limit: 10,
        query: search,
      });

      console.log(res);

      const vehiclesOptions = res.rows.map((vehicle) => ({
        label: handleLabel(vehicle),
        value: handleValue(vehicle),
      }));

      return {
        options: vehiclesOptions,
        hasMore: page * 10 < res.count,
        additional: {
          page: page + 1,
        },
      };
    } catch (err) {
      console.log(err);
      return {
        options: [],
        hasMore: false,
      };
    }
  }

  return (
    <AsyncPaginate
      isDisabled={disabled}
      key={currentInputKey}
      debounceTimeout={500}
      loadOptions={handleLoadVehicles}
      cacheOptions
      defaultValue={null}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e)}
      additional={{
        page: 1,
      }}
      noOptionsMessage={() => 'Sem opções'}
      loadingMessage={() => 'Carregando...'}
      styles={styles || defaultStyles}
      {...rest}
    />
  );
}
