import SearchInput from 'components/SearchInput';
import React from 'react';
import constants from 'utils/constants';

function QuotationsFilter({
  history,
  query,
  setQuery,
  status,
  setStatus,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
}) {
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
      style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}
    >
      <div className="quotations-row">
        <div>
          <button
            className="btn btn-sucesso"
            onClick={() => history.push(constants.ROUTES.NEW_QUOTATION)}
          >
            + Nova Cotação
          </button>
        </div>

        <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
          <SearchInput
            placeholder="Pesquisa por Código ou Fornecedores"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>

        <div style={{ display: 'flex' }}>
          <label>
            <strong>Status:</strong>
          </label>
          <select
            className="form-control foco-input"
            onChange={(event) => setStatus(event.target.value)}
            value={status}
          >
            <option value="">Todos</option>
            <option value="Respondida">Respondida</option>
            <option value="Enviada">Enviada</option>
            <option value="Não Enviada">Não Enviada</option>
            <option value="Cancelada">Cancelada</option>
          </select>
        </div>
      </div>

      <div className="quotations-row-data">
        <div>
          <span style={{ display: 'flex', alignItems: 'center', width: 100 }}>
            <strong>Data Inicial:</strong>
          </span>
          <input
            className="form-control foco-input"
            type="date"
            name="dataInicial"
            value={initialDate}
            max={finalDate}
            onChange={(e) => setInitialDate(e.target.value)}
            id="initial-date-span"
          />
        </div>

        <div>
          <span style={{ display: 'flex', alignItems: 'center', width: 100 }}>
            <strong>Data Final:</strong>
          </span>
          <input
            className="form-control foco-input"
            type="date"
            name="dataFinal"
            maxLength="2"
            value={finalDate}
            min={initialDate}
            onChange={(e) => setFinalDate(e.target.value)}
            id="final-date-span"
          />
        </div>
      </div>
    </div>
  );
}

export default QuotationsFilter;
