import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';

import Card from 'components/Card/Card.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import SearchInput from 'components/SearchInput';

import { kmMask } from 'client/components/ToNormalize/ToNormalize';
import warrantiesRepository from '../../../repositories/Warranties';
import useFilters from '../../../hooks/useFilters';
import { useAuth } from '../../../contexts/auth';
import constants from '../../../utils/constants';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { format } from 'date-fns';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

const HomeWarranties = ({ history }) => {
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [warranties, setWarranties] = useState([]);
  const [loading, setLoading] = useState(false);

  const { companyId } = useAuth();
  const { queryFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;

  useEffect(() => {
    if (!!companyId) {
      loadWarranties();
    }
  }, []);

  const loadWarranties = async () => {
    setLoading(true);

    try {
      const warranties = await warrantiesRepository.getAllByCompany(companyId);
      setWarranties(warranties);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as garantias. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (warranty) => {
    const querySearch = [warranty['Description']];
    return filterByQuery(querySearch);
  };

  const filteredWarranties = warranties.filter(handleFilters);
  return (
    <Card
      content={
        <div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            id="reviewHeader"
            style={{ marginTop: '10px' }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso"
                onClick={() =>
                  companyId
                    ? history.push(constants.ROUTES.WARRANTY)
                    : setNoCompanyModal(true)
                }
              >
                + Nova Garantia
              </button>
            </div>
            <div
              className="col-xs-12 col-sm-8 col-md-4 col-lg-4"
              id="review-search"
            >
              <SearchInput
                placeholder="Pesquisar por Descrição"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <DownloadXlsButton
                archiveName={`Garantias - ${format(new Date(), 'dd/MM/yyyy')}`}
                data={filteredWarranties}
                disabled={!!!filteredWarranties.length}
                className="btn btn-export"
                columns={[
                  {
                    name: 'Descrição',
                    acessor: 'Description',
                  },
                  {
                    name: 'KM',
                    acessor: 'Kilometers',
                  },
                  {
                    name: 'Meses',
                    acessor: 'Months',
                  },
                ]}
              >
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                Exportar .xls
              </DownloadXlsButton>
            </div>
          </div>
          <div>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={warranties.filter(handleFilters)}
              columns={[
                {
                  Header: 'Código',
                  id: 'Code',
                  accessor: (props) => Number(props.Code),
                  headerClassName: 'text-left',
                  width: 100,
                },
                {
                  Header: 'Descrição',
                  accessor: 'Description',
                  headerClassName: 'text-left',
                  width: 250,
                },
                {
                  Header: 'KM',
                  id: 'Kilometers',
                  accessor: (props) => Number(props.Kilometers), // FUNÇÃO PARA SETAR EM ORDEM NUMÉRICA
                  headerClassName: 'text-left',
                  width: 120,
                  Cell: (props) => kmMask(props.value.toString()),
                },
                {
                  Header: 'Meses',
                  id: 'Months',
                  accessor: (props) => Number(props.Months),
                  headerClassName: 'text-left',
                  width: 120,
                },
                {
                  Header: 'Ações',
                  accessor: 'Actions',
                  headerClassName: 'text-left',
                  width: 150,
                  filterable: false,
                  Cell: (props) => (
                    <Link to={{ pathname: `warranty#${props.original.id}` }}>
                      <FontAwesomeIcon
                        title="Editar"
                        cursor="pointer"
                        style={{
                          height: '1.5em',
                          width: '1.5em',
                          color: 'black',
                        }}
                        icon={faEdit}
                      />
                    </Link>
                  ),
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'Description',
                  desc: false,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </div>
      }
    />
  );
};

export default withRouter(HomeWarranties);
