import SearchInput from 'components/SearchInput'
import { addDays, format } from 'date-fns'
import React from 'react'
import constants from 'utils/constants'
import '../styles.css'

const PartsRequisitionFilter = ({
  history,
  setStatus,
  handleChangeQuery,
  employees,
  setEmployeeId,
  setInitialDate,
  setFinalDate,
  finalDate,
  initialDate,
}) => {
  return (
    <div className="parts-requisition__filter-page">
      <div className="parts-requisition__filter-row">
        <div className="parts-requisition__filter-button">
          <button
            className="btn btn-sucesso"
            onClick={() => history.push(constants.ROUTES.NEW_PART_REQUISITION)}
          >
            + Novo Lançamento
          </button>
        </div>

        <div className="parts-requisition__filter-button">
          <div
            style={{
              width: 300,
            }}
            className="search-input-wrapper"
          >
            <SearchInput
              placeholder="Pesquisa por Venda/OS/PDV ou Cliente"
              onChange={(e) => handleChangeQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="parts-requisition__filter-column">
          <strong>Status</strong>
          <select
            className="foco-input form-control"
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">Ambos</option>
            <option value="Entregue">Entregue</option>
            <option value="Cancelado">Cancelado</option>
          </select>
        </div>

        <div className="parts-requisition__filter-column">
          <strong>Funcionários</strong>
          <select
            className="foco-input form-control"
            onChange={(e) => setEmployeeId(e.target.value)}
          >
            <option value="">Todos</option>
            {employees.map((employee) => (
              <option key={employee.id} value={employee.id}>
                {employee.name}
              </option>
            ))}
          </select>
        </div>

        <div className="parts-requisition__filter-column">
          <strong>Data Inicial</strong>
          <input
            type="date"
            className="foco-input form-control"
            onChange={(e) => setInitialDate(e.target.value)}
            max={
              finalDate
                ? format(addDays(new Date(finalDate), 1), 'yyyy-MM-dd')
                : format(new Date(), 'yyyy-MM-dd')
            }
          />
        </div>

        <div className="parts-requisition__filter-column">
          <strong>Data Final</strong>
          <input
            type="date"
            className="foco-input form-control"
            onChange={(e) => setFinalDate(e.target.value)}
            max={format(new Date(), 'yyyy-MM-dd')}
            min={
              initialDate
                ? format(addDays(new Date(initialDate), 1), 'yyyy-MM-dd')
                : null
            }
          />
        </div>
      </div>
    </div>
  )
}

export default PartsRequisitionFilter
