import React from 'react'
import ReactTable from 'react-table'
import Toggle from 'react-toggle'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import { addDays, format } from 'date-fns'

export function PromotionalPriceTableList({
  loading,
  tablePromotionalPricesList,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  totalPages,
  handleUpdateStatus,
  handleIsDeleteConfirmationModalOpen,
}) {
  return (
    <div>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          marginTop: 20,
        }}
        data={tablePromotionalPricesList}
        columns={[
          {
            Header: 'Data Inicial',
            accessor: 'initialDate',
            width: 160,
            Cell: (props) => format(addDays(new Date(props.value), 1), 'dd/MM/yyyy'),
          },
          {
            Header: 'Data Final',
            accessor: 'finalDate',
            width: 160,
            Cell: (props) => format(addDays(new Date(props.value), 1), 'dd/MM/yyyy'),
          },
          {
            Header: 'Descrição',
            accessor: 'description',
            width: 330,
          },
          {
            Header: 'Qtd Produtos',
            accessor: 'productsQuantity',
            width: 130,
          },
          {
            Header: 'Qtd Serviços',
            accessor: 'servicesQuantity',
            width: 130,
          },
          {
            Header: 'Status',
            accessor: 'isActive',
            width: 130,
            Cell: (props) => {
              return (
                <div>
                  <Toggle
                    style={{ position: 'fixed' }}
                    checked={props.value}
                    onChange={() =>
                      handleUpdateStatus(props.original.id, !props.value)
                    }
                  />
                </div>
              )
            },
          },
          {
            Header: 'Ações',
            accessor: 'id',
            width: 130,
            Cell: (props) => (
              <div
                style={{
                  display: 'flex',
                  gap: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon
                  size="lg"
                  color="red"
                  cursor="pointer"
                  title="Excluir"
                  icon={faWindowClose}
                  onClick={() =>
                    handleIsDeleteConfirmationModalOpen(props.value)
                  }
                />
                <Link
                  to={{
                    pathname: `edit-promotional-price-table#${props.value}`,
                  }}
                  title="Editar"
                >
                  <FontAwesomeIcon
                    color="black"
                    size="lg"
                    cursor="pointer"
                    icon={faEdit}
                  />
                </Link>
              </div>
            ),
          },
        ]}
        pageSize={pageLimit}
        page={currentPage}
        onPageChange={setCurrentPage}
        manual
        onPageSizeChange={(value) => {
          setPageLimit(value)
        }}
        pages={totalPages}
        loading={loading}
        showPagination={true}
        defaultPageSize={10}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhuma tabela promocional encontrada"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </div>
  )
}
