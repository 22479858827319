import React from 'react';
import SearchInput from 'components/SearchInput';
import constants from 'utils/constants';
import '../styles.css';
import { addDays, format } from 'date-fns';

export function PromotionalPriceTableFilter({
  history,
  setStatus,
  setDateType,
  handleChangeQuery,
  handleChangeInitialDate,
  handleChangeFinalDate,
  initialDate,
  finalDate,
}) {
  return (
    <div className="promotional-price-table__filter-page">
      <div className="promotional-price-table__filter-row">
        <div className="promotional-price-table__filter-button">
          <button
            className="btn btn-sucesso"
            style={{
              height: '35px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() =>
              history.push(constants.ROUTES.NEW_PROMOTIONAL_PRICE_TABLE)
            }
          >
            + Nova Tabela
          </button>

          <div style={{ width: 300 }} className="search-input-wrapper">
            <SearchInput
              placeholder="Pesquisa por Descrição"
              onChange={(e) => handleChangeQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="promotional-price-table__filter-column">
          <span className="filter-column-title" style={{ fontWeight: 'bold' }}>
            Status:
          </span>
          <select
            className="foco-input form-control"
            style={{ height: '35px' }}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value={''}>Ambos</option>
            <option value={true}>Ativo</option>
            <option value={false}>Inativo</option>
          </select>
        </div>

        <div className="promotional-price-table__filter-column">
          <span className="filter-column-title" style={{ fontWeight: 'bold' }}>
            Tipo de Data:
          </span>
          <select
            className="foco-input form-control"
            style={{ height: '35px', width: '150px' }}
            onChange={(e) => setDateType(e.target.value)}
          >
            <option value="initialDate">Inicial</option>
            <option value="finalDate">Final</option>
          </select>
        </div>

        <div className="promotional-price-table__filter-column">
          <span className="filter-column-title" style={{ fontWeight: 'bold' }}>
            Data Inicial:
          </span>
          <input
            type="date"
            className="foco-input form-control"
            style={{ height: '35px', width: '150px' }}
            onChange={(e) => handleChangeInitialDate(e.target.value)}
            min={format(new Date(), 'yyyy-MM-dd')}
            max={
              finalDate
                ? format(addDays(new Date(finalDate), 1), 'yyyy-MM-dd')
                : null
            }
          />
        </div>

        <div className="promotional-price-table__filter-column">
          <span className="filter-column-title" style={{ fontWeight: 'bold' }}>
            Data Final:
          </span>
          <input
            type="date"
            className="foco-input form-control"
            style={{ height: '35px', width: '150px' }}
            onChange={(e) => handleChangeFinalDate(e.target.value)}
            min={
              initialDate
                ? format(addDays(new Date(initialDate), 1), 'yyyy-MM-dd')
                : format(new Date(), 'yyyy-MM-dd')
            }
          />
        </div>
      </div>
    </div>
  );
}
