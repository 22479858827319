import React from 'react';
import { Modal } from 'react-bootstrap';

export default function AlertModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={() => (props?.loading ? {} : props.onHide())}
      animation={props.animation}
      dialogClassName={props.dialogClassName ? props.dialogClassName : ''}
      backdrop={props.backdrop}
    >
      <Modal.Header closeButton={props.hideCloseButton ? false : true}>
        <Modal.Title>
          <strong>{!props.title ? 'O.S Digital' : props.title}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.subtitle && (
          <div style={{ marginBottom: '10px' }}>
            <strong>{props.subtitle}</strong>
          </div>
        )}
        {props.message}
      </Modal.Body>
      {!!props.customFooter ? (
        <Modal.Footer>{props.customFooter}</Modal.Footer>
      ) : (
        <Modal.Footer>
          <div className="flex end gap-075">
            <button
              className="button button-red button-h35"
              onClick={props.onCancel}
              disabled={props.loading}
            >
              {props.onCancelLabel ? props.onCancelLabel : 'Não'}
            </button>

            <button
              className="button button-green button-h35"
              onClick={props.onSubmit}
              disabled={props.loading}
            >
              {props.onSubmitLabel ? props.onSubmitLabel + ' ' : 'Sim '}
              <span
                className={props?.loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}
              />
            </button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}
