import React from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import 'react-tabs/style/react-tabs.css'
import HomeBillsToPay from './BillsToPay'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

import './styles.css'

const BillsToPay = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <BreadCrumb
              data={['Início', 'Contas a Pagar']}
              path={['home', null]}
            />

            <HomeBillsToPay />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default BillsToPay
