import React, { useState, useEffect } from 'react';
import Card from 'components/Card/Card.jsx';
import { Link, withRouter } from 'react-router-dom';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import ReactTable from 'react-table';
import Button from 'client/components/CustomButton/Button.jsx';
import CustomButton from 'client/components/CustomButton/CustomButton';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { format, isAfter, addDays, subDays } from 'date-fns';
import billsToPayRepository from '../../../repositories/BillsToPay';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany';
import TitleQuickDischargeModal from 'client/components/TitleQuickDischargeModal';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import constants from '../../../utils/constants';
import DownloadXlsButton from '../../../components/DownloadXlsButton';
import { getBillsToPayInfoToExport, xlsColumns } from './excelHelpers';
import { GenerateCurrentBill } from './GenerateCurrentBill';
import RegistrationMethodModal from './components/RegistrationMethodModal';
import accountPlansRepository from 'repositories/AccountPlans';
import cashierBankRepository from 'repositories/CashierBank';
import ConsultPayableAmountBtn from './components/ConsultPayableAmountBtn';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import useDebounce from 'hooks/useDebounce';
import SalesSelectionModal from './components/PurchasesSelectionModal';

const HomeBillsToPay = ({ history }) => {
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [billsToPay, setBillsToPay] = useState([]);
  const [isGenerateCurrentBillModalOpen, setIsGenerateCurrentBillModalOpen] =
    useState(false);
  const [isQuickDischargeModalOpen, setIsQuickDischargeModalOpen] =
    useState(false);

  const [dataToExport, setDataToExport] = useState([]);
  const [isXlsExportReady, setIsXlsExportReady] = useState(false);
  const [isXlsExportLoading, setIsXlsExportLoading] = useState(false);

  const companyId = localStorage.getItem('ID_EMPRESA');

  const [cashierBank, setCashierBank] = useState([]);
  const [accountPlans, setAccountPlans] = useState([]);

  const [status, setStatus] = useState('');
  const [accountPlanId, setAccountPlanId] = useState('');
  const [cashierBankId, setCashierBankId] = useState('');
  const [query, setQuery] = useState('');
  const [queryDebounced, setQueryDebounced] = useState('');
  const [initialDateDebounced, setInitialDateDebounced] = useState(null);
  const [finalDateDebounced, setFinalDateDebounced] = useState(null);
  const [isRegistrationMethodModalOpen, setRegistrationMethodModalOpen] =
    useState(false);

  const [isPurchasesSelectionModalOpen, setIsPurchasesSelectionModalOpen] =
    useState(false);

  const debouncedSaveQuery = useDebounce(setQueryDebounced, 800);
  const debouncedSaveInitialDate = useDebounce(setInitialDateDebounced, 800);
  const debouncedSaveFinalDate = useDebounce(setFinalDateDebounced, 800);

  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);

  const [page, setPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(10);

  const { isPlanFree, isPlanStart, isPlanBasic } = usePlanSignatureContext();

  const [isInitialFiltersDefined, setIsInitialFiltersDefined] = useState(false);

  function handleChangeQuery(value) {
    setQuery(value);
    debouncedSaveQuery(value);
  }

  function handleChangeInitialDate(value) {
    setInitialDate(value);
    debouncedSaveInitialDate(value);
  }

  function handleChangeFinalDate(value) {
    setFinalDate(value);
    debouncedSaveFinalDate(value);
  }

  function handleSetInitialFilters() {
    const params = new URLSearchParams(window.location.search);
    const receive = params.get('receive');
    if (receive) {
      setStatus(constants.BILLS_STATUS.OPEN);
      if (receive == 'today') {
        let dataInicial = format(new Date(), 'yyyy-MM-dd');
        setInitialDate(dataInicial);
        setFinalDate(dataInicial);
        setInitialDateDebounced(dataInicial);
        setFinalDateDebounced(dataInicial);
      } else {
        let diaAnterior = format(subDays(new Date(), 1), 'yyyy-MM-dd');
        setFinalDate(diaAnterior);
        setFinalDateDebounced(diaAnterior);
      }
    }
    setIsInitialFiltersDefined(true);
  }

  useEffect(() => {
    if (!isInitialFiltersDefined) return;
    getBillsToPay();
  }, [
    page,
    pageLimit,
    cashierBankId,
    accountPlanId,
    status,
    initialDateDebounced,
    finalDateDebounced,
    queryDebounced,
    isInitialFiltersDefined,
  ]);

  useEffect(() => {
    loadActiveAccountPlans();
    loadActiveCashierBanks();
  }, []);

  useEffect(() => {
    handleSetInitialFilters();
  }, []);

  const getBillsToPay = async () => {
    setLoading(true);
    setIsXlsExportReady(false);

    try {
      const response = await billsToPayRepository.getAllByCompany(companyId, {
        page: page + 1,
        limit: pageLimit,
        cashierBankId,
        accountPlanId,
        billStatusId: status,
        initialDate: initialDateDebounced,
        finalDate: finalDateDebounced,
        query: queryDebounced,
      });

      const serializedBills = response.bills.map((bills) => {
        bills.invoice = !bills.invoice
          ? !bills.Purchases?.invoice
            ? ''
            : bills.Purchases?.invoice
          : bills.invoice;
        return bills;
      });

      setTotalPages(Math.ceil(response.count / pageLimit));
      setBillsToPay(serializedBills);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao carregar títulos. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const loadDataToExport = async () => {
    setIsXlsExportLoading(true);
    toastr.warning(
      'Preparando Exportação',
      'Avisaremos quando estiver concluída. Por favor, aguarde.'
    );
    try {
      const response = await billsToPayRepository.getAllByCompany(companyId, {
        cashierBankId,
        accountPlanId,
        billStatusId: status,
        initialDate: initialDateDebounced,
        finalDate: finalDateDebounced,
        query: queryDebounced,
      });

      const serializedBills = response.bills.map((bills) => {
        bills.invoice = !bills.invoice
          ? !bills.Purchases?.invoice
            ? ''
            : bills.Purchases?.invoice
          : bills.invoice;
        return bills;
      });

      const serializedDataToExport = getBillsToPayInfoToExport(serializedBills);
      setDataToExport(serializedDataToExport);
      setIsXlsExportLoading(false);
      setIsXlsExportReady(true);

      toastr.success(
        'Exportação Pronta',
        'Clique no botão para baixar o relatório'
      );
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao carregar títulos. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSuccessGenerateCurrentBill = () => {
    setIsGenerateCurrentBillModalOpen(false);
    getBillsToPay();
  };

  const handleNewTitleButton = () => {
    if (!isPlanFree && !isPlanStart && !isPlanBasic) {
      setRegistrationMethodModalOpen(true);
    } else {
      history.push(constants.ROUTES.BILL_TO_PAY);
    }
  };

  async function loadActiveAccountPlans() {
    try {
      const { data } = await accountPlansRepository.index({ companyId });
      const accountPlansCredit = data.filter(
        (accountPlan) =>
          accountPlan.type === 'Débito' && accountPlan.isActive === true
      );

      setAccountPlans(accountPlansCredit);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar o Plano de Contas. Por favor, tente novamente.'
      );
    }
  }

  async function loadActiveCashierBanks() {
    try {
      const response =
        await cashierBankRepository.getAllByCompanyActiveSortedByDescription(
          companyId
        );

      setCashierBank(response);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar os Caixas/Bancos. Por favor, tente novamente.'
      );
    }
  }

  return (
    <Card
      css={{
        paddingLeft: 0,
      }}
      content={
        <div id="bills-to-pay-page">
          <header>
            <section
              className="bills-to-pay_row"
              style={{
                paddingLeft: 0,
                marginLeft: '-15px',
                fontSize: '12px',
                marginTop: '-10px',
              }}
            >
              <div>
                <ModalAlertCompany
                  show={noCompanyModal}
                  onHide={() => setNoCompanyModal(false)}
                />
                <button
                  id="btn-nova-revisao"
                  className="btn btn-sucesso"
                  style={{
                    fontSize: '12px',
                  }}
                  onClick={() =>
                    !!companyId
                      ? handleNewTitleButton()
                      : setNoCompanyModal(true)
                  }
                >
                  + Novo Título
                </button>
              </div>
              <div>
                <i
                  id="iconepesquisar"
                  style={{ cursor: 'pointer' }}
                  className="fa fa-search"
                />
                <input
                  type="text"
                  style={{ paddingLeft: '30px', width: '312px' }}
                  className="form-control foco-input"
                  placeholder="Pesquisa por Título, Compra ou Fornecedor"
                  value={query}
                  onChange={(e) => handleChangeQuery(e.target.value)}
                />
              </div>

              <div className="bills-to-pay_column">
                <span>
                  <strong>Status: </strong>
                </span>
                <select
                  className="form-control foco-input"
                  style={{ width: '113px' }}
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="">Todos</option>
                  <option value={constants.BILLS_STATUS.OPEN}>Aberto</option>
                  <option value={constants.BILLS_STATUS.CLOSED}>
                    Finalizado
                  </option>
                  <option value={constants.BILLS_STATUS.CANCELED}>
                    Cancelado
                  </option>
                </select>
              </div>

              <div>
                {isXlsExportReady ? (
                  <DownloadXlsButton
                    data={dataToExport}
                    className="btn btn-export"
                    archiveName={`contas-a-pagar-${format(
                      new Date(),
                      'dd/MM/yyyy'
                    )}`}
                    columns={xlsColumns}
                  >
                    <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                    Baixar Relatório
                  </DownloadXlsButton>
                ) : (
                  <button
                    className="btn btn-export"
                    onClick={() => loadDataToExport()}
                    disabled={isXlsExportLoading}
                  >
                    {isXlsExportLoading ? (
                      <span className="fa fa-spinner fa-pulse fa-1x"></span>
                    ) : (
                      <FontAwesomeIcon
                        color="white"
                        icon={faCloudDownloadAlt}
                      />
                    )}
                    &nbsp;Exportar .xls
                  </button>
                )}
              </div>

              <div>
                <button
                  className="btn"
                  style={{
                    backgroundColor: '#1d7391',
                    borderColor: '#1d7391',
                    color: 'white',
                    height: 38,
                    fontSize: '12px',
                  }}
                  onClick={() => setIsQuickDischargeModalOpen(true)}
                >
                  Baixa Rápida de Títulos
                </button>
              </div>

              <div>
                <CustomButton
                  onClick={() => setIsGenerateCurrentBillModalOpen(true)}
                  bsStyle="warning"
                  fill
                  style={{
                    backgroundColor: '#f0ad4e',
                    height: 38,
                    fontSize: '12px',
                  }}
                >
                  Gerar Conta Recorrente
                </CustomButton>
              </div>
            </section>

            <section
              className="bills-to-pay_row"
              style={{ fontSize: '12px', marginLeft: '-15px' }}
            >
              <div className="bills-to-pay_column">
                <span>
                  <strong>Data Inicial: </strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="date"
                  name="dataInicial"
                  style={{ marginRight: '10px' }}
                  value={initialDate}
                  onChange={(e) => handleChangeInitialDate(e.target.value)}
                />
              </div>

              <div className="bills-to-pay_column">
                <span>
                  <strong>Data Final: </strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="date"
                  name="dataFinal"
                  style={{ marginRight: '10px' }}
                  value={finalDate}
                  onChange={(e) => handleChangeFinalDate(e.target.value)}
                />
              </div>

              <div className="bills-to-pay_column">
                <span>
                  <strong>Plano de Contas: </strong>
                </span>
                <select
                  className="form-control foco-input"
                  style={{ width: '170px', marginRight: '5px' }}
                  value={accountPlanId}
                  onChange={(e) => setAccountPlanId(e.target.value)}
                >
                  <option value="">Todos</option>
                  {accountPlans.map(({ id, description }) => (
                    <option key={id} value={id}>
                      {description}
                    </option>
                  ))}
                </select>
              </div>

              <div className="bills-to-pay_column">
                <span>
                  <strong>Caixa/Bancos: </strong>
                </span>
                <select
                  className="form-control foco-input"
                  style={{ width: '160px' }}
                  value={cashierBankId}
                  onChange={(e) => setCashierBankId(e.target.value)}
                >
                  <option value="">Todos</option>
                  {cashierBank.map(
                    ({ id, description, CashierBankType: { initials } }) => (
                      <option key={id} value={id}>
                        {initials} - {description}
                      </option>
                    )
                  )}
                </select>
              </div>

              {!isPlanFree && !isPlanStart && <ConsultPayableAmountBtn />}
            </section>
          </header>

          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
              }}
              data={billsToPay}
              columns={[
                {
                  Header: 'Título',
                  accessor: (props) => Number(props.code),
                  id: 'code',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 80,
                  Cell: (props) => (
                    <>
                      <Link
                        id="linkVenda"
                        to={{ pathname: `bill-to-pay#${props.original.id}` }}
                        title="Clique para editar"
                      >
                        <button className="btn-link">{props.value}</button>
                      </Link>
                    </>
                  ),
                },
                {
                  Header: 'Compra',
                  accessor: 'BillsToPayPurchases',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 80,
                  Cell: (props) => {
                    if (props.value.length > 0) {
                      return props.value.length > 1
                        ? 'Várias'
                        : props.value[0]?.Purchases?.code || '---';
                    } else {
                      return props.original.Purchases?.code || '---';
                    }
                  },
                },
                {
                  Header: 'Nota',
                  accessor: 'invoice',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 80,
                  Cell: (props) =>
                    props.value ? <span>{props.value}</span> : <span>---</span>,
                },
                {
                  Header: 'Vencimento',
                  accessor: 'dueDate',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 150,
                  Cell: (props) => (
                    <span
                      style={{
                        color: isAfter(
                          new Date(),
                          addDays(getDateOnlyFromDate(props.value), 1)
                        )
                          ? 'red'
                          : '',
                      }}
                    >
                      {format(getDateOnlyFromDate(props.value), 'dd/MM/yyyy')}
                    </span>
                  ),
                },
                {
                  Header: 'Fornecedor',
                  accessor: 'Provider.companyName',
                  headerClassName: 'text-center',
                  className: 'texto',
                },

                {
                  Header: 'Valor em aberto',
                  accessor: 'openValue',
                  headerClassName: 'text-center',
                  width: 120,
                  className: 'texto',
                  Cell: (props) => <a>{currency(props.value)}</a>,
                },
                {
                  Header: 'Valor Pago',
                  accessor: 'paidValue',
                  headerClassName: 'text-center',
                  width: 120,
                  className: 'texto',
                  Cell: (props) =>
                    props?.value ? <a>{currency(props.value)}</a> : '',
                },
                {
                  Header: 'Total',
                  accessor: 'liquidValue',
                  headerClassName: 'text-center',
                  width: 120,
                  className: 'texto',
                  Cell: (props) => (
                    <a>{currency(props.value + props.original.addedValue)}</a>
                  ),
                },
                {
                  Header: 'Status',
                  accessor: 'billStatusId',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 90,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor:
                          props.value === constants.BILLS_STATUS.OPEN
                            ? '#ffcc00'
                            : props.value === constants.BILLS_STATUS.CLOSED
                            ? 'green'
                            : 'red',
                      }}
                    >
                      {constants.BILLS_STATUS_DESCRIPTION[props.value]}
                    </Button>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  headerClassName: 'text-center',
                  filterable: false,
                  className: 'texto',
                  width: 120,
                  style: { display: 'flex', justifyContent: 'center' },
                  Cell: (props) => (
                    <>
                      <Link
                        to={{ pathname: `bill-to-pay#${props.value}` }}
                        title="Editar"
                      >
                        <FontAwesomeIcon
                          cursor="pointer"
                          icon={faEdit}
                          style={{ width: '1.5em', height: '1.5em' }}
                        />
                      </Link>
                    </>
                  ),
                },
              ]}
              defaultPageSize={10}
              pages={totalPages}
              onPageSizeChange={(value) => setPageLimit(value)}
              onPageChange={(value) => setPage(value)}
              manual
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'code',
                  desc: true,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhuma compra ou título foi encontrado"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>

          {isGenerateCurrentBillModalOpen && (
            <GenerateCurrentBill
              onCancel={() => setIsGenerateCurrentBillModalOpen(false)}
              onSuccess={handleSuccessGenerateCurrentBill}
            />
          )}

          {isQuickDischargeModalOpen && (
            <TitleQuickDischargeModal
              initialValues={{ page: 1 }}
              billType="toPay"
              onCancel={() => setIsQuickDischargeModalOpen(false)}
              loadTitles={getBillsToPay}
            />
          )}

          {isRegistrationMethodModalOpen && (
            <RegistrationMethodModal
              handleClose={() => setRegistrationMethodModalOpen(false)}
              isOpen={isRegistrationMethodModalOpen}
              handleOpenPurchasesSelectionModal={() =>
                setIsPurchasesSelectionModalOpen(true)
              }
            />
          )}

          {isPurchasesSelectionModalOpen && (
            <SalesSelectionModal
              isOpen={isPurchasesSelectionModalOpen}
              handleClose={() => setIsPurchasesSelectionModalOpen(false)}
              history={history}
            />
          )}
        </div>
      }
    />
  );
};

export default withRouter(HomeBillsToPay);
