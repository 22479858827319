import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTools,
  faEdit,
  faReceipt,
  faUserCog,
} from '@fortawesome/free-solid-svg-icons';
import { Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import Card from 'components/Card/Card.jsx';
import api from 'services/api';
import config from 'config';
import { cnpjMask, cpfMask } from 'client/components/ToNormalize/ToNormalize';
import { useAuth } from '../../../contexts/auth';
import { usePlanSignatureContext } from '../../../contexts/plan-signature';
import UnavailableFeatureModal from '../../components/UnavailableFeatureModal';
import constants from '../../../utils/constants';

const HomeMyCompanies = () => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [blockTax, setBlockTax] = useState(false);

  const history = useHistory();

  const { userId, companyId } = useAuth();
  const { isPlanFree, isPlanStart } = usePlanSignatureContext();

  useEffect(() => {
    loadCompanies();
  }, []);

  const loadCompanies = async () => {
    setLoading(true);
    try {
      const companyUsers = await api.get(
        config.endpoint + `companyUser/byId/${userId}`
      );

      const serializedCompanies = !!companyUsers.data[0].Company_id
        ? companyUsers.data.map((companyUser) => ({
            ...companyUser.Company,
          }))
        : [];

      setCompanies(serializedCompanies);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao buscar as empresas. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClickCompanyTaxData = () => {
    if (isPlanFree || isPlanStart) {
      return setBlockTax(true);
    } else {
      return history.push(constants.ROUTES.COMPANY_TAX_DATA);
    }
  };

  return (
    <Card
      content={
        <>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            id="cbTitulo"
            style={{ marginTop: '15px' }}
          >
            <p id="cbTituloTagP">Minha Empresa</p>
          </Col>
          <br />
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            id="reviewHeader"
          >
            <Link to="MeusDados">
              <button
                className={`btn btn-sucesso ${companyId ? 'hidden' : ''}`}
                disabled={companies.length > 0}
              >
                + Nova Empresa
              </button>
            </Link>
          </div>
          <ReactTable
            style={{ fontWeight: 'bold', textAlign: 'center', width: '880px' }}
            data={companies}
            columns={[
              {
                Header: 'CPF / CNPJ',
                accessor: 'Cpf_Cnpj',
                width: 200,
                Cell: (props) =>
                  props.value.length === 14
                    ? cnpjMask(props.value)
                    : cpfMask(props.value),
              },
              {
                Header: 'Nome / Razão Social',
                accessor: 'Company_Name',
                width: 250,
              },
              {
                Header: 'Meus Dados',
                width: 100,
                filterable: false,
                Cell: () => (
                  <Link to="MeusDados" title="Editar Empresa">
                    <FontAwesomeIcon icon={faEdit} />
                  </Link>
                ),
              },
              {
                Header: 'Meus Detalhes',
                width: 110,
                filterable: false,
                Cell: () => (
                  <Link to="MeusDetalhes" title="Editar Detalhes">
                    <FontAwesomeIcon icon={faTools} />
                  </Link>
                ),
              },
              {
                Header: 'Dados Fiscais',
                width: 100,
                filterable: false,
                Cell: () => (
                  <FontAwesomeIcon
                    icon={faReceipt}
                    cursor="pointer"
                    onClick={handleClickCompanyTaxData}
                  />
                ),
              },
              {
                Header: 'Configurações',
                width: 110,
                filterable: false,
                Cell: () => (
                  <Link to="configurations">
                    <FontAwesomeIcon icon={faUserCog} />
                  </Link>
                ),
              },
            ]}
            defaultPageSize={5}
            loading={loading}
            showPagination
            sortable={false}
            showPaginationTop={false}
            showPaginationBottom
            pageSizeOptions={[5, 10, 20, 25, 50, 100]}
            defaultSorted={[
              {
                id: 'Description',
                desc: false,
              },
            ]}
            previousText="Anterior"
            nextText="Próximo"
            loadingText="Carregando..."
            noDataText="Não há informação"
            pageText="Página"
            ofText="de"
            rowsText="linhas"
          />

          <UnavailableFeatureModal
            show={blockTax}
            onHide={() => setBlockTax(false)}
          />
        </>
      }
    />
  );
};

export default HomeMyCompanies;
