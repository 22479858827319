import React, { useEffect, useState } from 'react';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { DefaultContentContainer } from 'v2/components/layout';
import { getBreadcrumbData } from './utils';
import { optionsIsActiveSelect } from './utils';
import { ActiveStatusLabel } from 'v2/components/ActiveStatusLabel';
import { useRouter } from 'v2/hooks/useRouter';
import { useAuth } from 'contexts/auth';
import useDebounce from 'hooks/useDebounce';
import { SearchInput } from 'v2/components/form';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { InputSelectDefault } from 'v2/components/Input';
import { Table } from 'v2/components/Table';
import { toastr } from 'react-redux-toastr';
import { TableActions } from './TableActions';
import { listDefects } from 'v2/repositories/DefectsRepository';

export function Defects() {
  const { navigateToClientPage } = useRouter();
  const { companyId } = useAuth();
  const [queryInput, setQueryInput] = useState('');
  const debounceQueryInput = useDebounce(setQueryInput, 300);
  const [isActiveSelect, setIsActiveSelect] = useState(
    optionsIsActiveSelect[0]
  );

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState([]);

  const [isFetching, setIsFetching] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const handleTriggerFetch = () => {
    setTriggerFetch(true);

    setTimeout(() => {
      setTriggerFetch(false);
    }, [800]);
  };

  const handleFetch = async () => {
    setIsFetching(true);

    try {
      const res = await listDefects({
        page: page + 1,
        limit,
        status: isActiveSelect.value,
        query: queryInput,
        companyId,
      });
      setCount(res.count);
      setRows(res.rows);
      setPages(Math.ceil(res.count / limit));
    } catch (err) {
      console.log(err);
      toastr.err(
        'Erro ao carregar os dados',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsFetching(false);
    }
  };

  const formatCorrectionsDetails = (correctionsAssociated) => {
    if (!correctionsAssociated || correctionsAssociated.length === 0) {
      return 'Nenhuma correção associada';
    }

    const formattedDetails = correctionsAssociated.map(
      (correction) => `${correction.description} - ${correction.details}`
    );

    return formattedDetails.length;
  };

  const columns = [
    {
      Header: 'Descrição',
      accessor: 'description',
    },
    {
      Header: 'Correções Vinculadas',
      accessor: 'linkedCorrections',
      width: 160,
      Cell: (props) => (
        <div>
          {formatCorrectionsDetails(props.original.correctionsAssociated)}
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'isActive',
      width: 120,
      Cell: (props) => {
        return <ActiveStatusLabel isActive={props.original.status} />;
      },
    },
    {
      Header: 'Ações',
      accessor: 'id',
      width: 90,
      Cell: (props) => {
        return (
          <TableActions
            id={props.original.id}
            triggerFetch={handleTriggerFetch}
          />
        );
      },
    },
  ];

  useEffect(() => {
    handleFetch();
  }, [queryInput, isActiveSelect, companyId, page]);

  useEffect(() => {
    if (triggerFetch) {
      handleFetch();
    }
  }, [triggerFetch]);

  return (
    <DefaultContentContainer>
      <BreadCrumb {...getBreadcrumbData()} />
      <div className="content__main">
        <div className="flex gap-1">
          <button
            className="button button-green button-h35"
            onClick={() => navigateToClientPage('defects', 'form_new')}
          >
            <span>+ Novo Defeito</span>
          </button>
          <SearchInput
            id="input__query"
            placeholder="Pesquisa por Descrição ou Detalhes"
            value={queryInput}
            width={300}
            onChange={(e) => debounceQueryInput(e.target.value)}
          />

          <InputContainer variant="row">
            <InputLabel htmlFor="input__isActive">Status:</InputLabel>
            <InputSelectDefault
              id="input__isActive"
              width={'130px'}
              height="35px"
              options={optionsIsActiveSelect}
              value={isActiveSelect}
              onChange={(value) => setIsActiveSelect(value)}
            />
          </InputContainer>
        </div>

        <div className="mt-2" style={{ width: '750px' }}>
          <Table
            columns={columns}
            data={rows}
            pageSize={limit}
            page={page}
            onPageChange={setPage}
            manual
            onPageSizeChange={setLimit}
            pages={pages}
            loading={isFetching}
          />
        </div>
      </div>
    </DefaultContentContainer>
  );
}
