import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import useFilters from '../../../hooks/useFilters';
import { useAuth } from '../../../contexts/auth';
import constants from '../../../utils/constants';

import { financialMovementsRepository } from '../../../repositories/FinancialMovements';

import Button from 'client/components/CustomButton/Button.jsx';
import Card from 'components/Card/Card.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany';
import AlertModal from 'components/AlertModal';

const HomeFinancialMovements = ({ history }) => {
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [movements, setMovements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [id, setId] = useState('');

  const { companyId } = useAuth();
  const { queryFilter, dateFilter, typeFilter, statusFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const { initialDate, setInitialDate, finalDate, setFinalDate, filterByDate } =
    dateFilter;
  const { type, setType, filterByType } = typeFilter;
  const { status, setStatus, filterByStatus } = statusFilter;

  useEffect(() => {
    loadMovements();
  }, []);

  const loadMovements = async () => {
    setLoading(true);
    try {
      const res = await financialMovementsRepository.getAllByCompany(companyId);
      setMovements(res);
    } catch (err) {
      return toastr.warning(
        'Ocorreu um erro ao carregar as movimentações. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const cancel = async () => {
    try {
      await financialMovementsRepository.cancel(id);
      setIsCancelModalOpen(false);
      loadMovements();
      return toastr.success('Movimentação cancelada com sucesso!');
    } catch (err) {
      return toastr.warning(
        'Ocorreu um erro ao cancelar a movimentação. Por favor, tente novamente'
      );
    }
  };

  const renderBtnStatus = (value) => {
    let color = '';
    switch (value) {
      case 'Em Aberto':
        color = '#f0ad4e';
        break;
      case 'Finalizada':
        color = '#46a546';
        break;
      case 'Cancelada':
        color = '#bd362f';
        break;
      default:
        break;
    }

    return (
      <Button
        id="botaoReactTable"
        style={{
          backgroundColor: color,
        }}
      >
        {value}
      </Button>
    );
  };

  function showModal(id) {
    setId(id);
    setIsCancelModalOpen(true);
  }

  function handleFilters(movement) {
    const searchQuery = [
      movement['description'],
      movement['code'],
      movement['value'],
    ];
    return (
      filterByQuery(searchQuery) &&
      filterByStatus('financialMovementStatusId', movement) &&
      filterByDate('createdAt', movement) &&
      filterByType('financialMovementTypeId', movement)
    );
  }

  return (
    <Card
      content={
        <div id="bills-to-receive-page">
          <header className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <section style={{ marginTop: '10px' }}>
              <div>
                <ModalAlertCompany
                  show={noCompanyModal}
                  onHide={() => setNoCompanyModal(false)}
                />
                <button
                  id="btn-nova-revisao"
                  className="btn btn-sucesso"
                  onClick={() =>
                    !!companyId
                      ? history.push(constants.ROUTES.FINANCIAL_MOVEMENT)
                      : setNoCompanyModal(true)
                  }
                >
                  + Nova Movimentação
                </button>
              </div>
              <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                <i
                  id="iconepesquisar"
                  style={{ cursor: 'pointer' }}
                  className="fa fa-search"
                />
                <input
                  type="text"
                  style={{ paddingLeft: '30px' }}
                  className="form-control foco-input"
                  placeholder="Pesquisar por Código, Descrição ou Valor"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  minWidth: 'fit-content',
                }}
              >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <strong>Tipo: </strong>
                </span>
                <select
                  className="form-control foco-input"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  style={{ marginLeft: '10px' }}
                >
                  <option value="">Ambos</option>
                  <option value="1">Movimentação</option>
                  <option value="2">Transferência</option>
                </select>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: '10px',
                }}
              >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <strong>Status: </strong>
                </span>
                <select
                  className="form-control foco-input"
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                  style={{ marginLeft: '10px' }}
                >
                  <option value="">Todos</option>
                  <option value="1">Em aberto</option>
                  <option value="2">Finalizada</option>
                  <option value="3">Cancelada</option>
                </select>
              </div>
            </section>

            <section className="filters-wrapper">
              <div>
                <span>
                  <strong>Data Inicial: </strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="date"
                  name="dataInicial"
                  style={{ marginRight: '10px' }}
                  value={initialDate}
                  onChange={(e) => setInitialDate(e.target.value)}
                />
              </div>

              <div>
                <span>
                  <strong>Data Final: </strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="date"
                  name="dataFinal"
                  style={{ marginRight: '5px' }}
                  value={finalDate}
                  onChange={(e) => setFinalDate(e.target.value)}
                />
              </div>
            </section>
          </header>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
              }}
              data={movements.filter(handleFilters)}
              columns={[
                {
                  Header: 'Dt. Criação',
                  accessor: 'createdAt',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 100,
                  Cell: (props) => format(new Date(props.value), 'dd/MM/yyyy'),
                },
                {
                  Header: 'Código',
                  accessor: 'code',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 80,
                },
                {
                  Header: 'Tipo',
                  accessor: 'FinancialMovementType.description',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 150,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor:
                          props.value === 'Movimentação'
                            ? '#428bca'
                            : '#5cb85c',
                      }}
                    >
                      {props.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Descrição',
                  accessor: 'description',
                  headerClassName: 'text-center',
                  className: 'texto',
                },
                {
                  Header: 'Valor',
                  accessor: 'value',
                  headerClassName: 'text-center',
                  width: 120,
                  className: 'texto',
                  Cell: (props) => <>{currency(props.value)}</>,
                },
                {
                  Header: 'Status',
                  accessor: 'FinancialMovementStatus.description',
                  headerClassName: 'text-center',
                  width: 120,
                  className: 'texto',
                  Cell: (props) => renderBtnStatus(props.value),
                },
                {
                  Header: 'Ações',
                  accessor: 'FinancialMovementStatus.description',
                  filterable: false,
                  className: 'texto',
                  width: 80,
                  style: { display: 'flex' },
                  Cell: (props) => (
                    <div style={{ marginLeft: '10px' }}>
                      <Link
                        to={{
                          pathname: `financial-movement#${props.original.id}`,
                        }}
                        title="Editar"
                      >
                        <FontAwesomeIcon
                          cursor="pointer"
                          icon={faEdit}
                          style={{
                            width: '1.5em',
                            height: '1.5em',
                            color: '#000',
                          }}
                        />
                      </Link>

                      <FontAwesomeIcon
                        cursor="pointer"
                        icon={faWindowClose}
                        style={{
                          width: '1.5em',
                          height: '1.5em',
                          marginLeft: '5px',
                          color: '#d43f3a',
                          display: props.value === 'Cancelada' ? 'none' : '',
                        }}
                        onClick={() => showModal(props.original.id)}
                      />
                    </div>
                  ),
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'code',
                  desc: true,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhuma movimentação foi encontrada"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>

          <AlertModal
            show={isCancelModalOpen}
            onHide={() => setIsCancelModalOpen(false)}
            onCancel={() => setIsCancelModalOpen(false)}
            onSubmit={() => cancel()}
            loading={loading}
            title="Cancelamento de movimentação"
            message="Deseja cancelar a movimentação? Não será possível reverter o processo posteriormente."
          />
        </div>
      }
    />
  );
};

export default withRouter(HomeFinancialMovements);
