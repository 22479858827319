import React, { useState } from 'react'

import ConsultPayableAmountModal from 'client/components/ConsultPayableAmountModal'

export default function ConsultPayableAmountBtn() {
  const [isConsultPayableModalOpen, setIsConsultPayableModalOpen] =
    useState(false)

  return (
    <div>
      <button
        className="btn"
        style={{
          backgroundColor: '#5bc0de',
          borderColor: '#5bc0de',
          color: 'white',
          marginLeft: 10,
          fontSize: '12px',
          height: 38,
        }}
        onClick={() => setIsConsultPayableModalOpen(true)}
      >
        Previsão de Pagamentos
      </button>

      {isConsultPayableModalOpen && (
        <ConsultPayableAmountModal
          isOpen={isConsultPayableModalOpen}
          handleClose={() => setIsConsultPayableModalOpen(false)}
        />
      )}
    </div>
  )
}
