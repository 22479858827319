import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import Toggle from 'react-toggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Link, withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { Modal } from 'react-bootstrap';
import FormPayment from './NewFormOfPayment';

import Card from 'components/Card/Card.jsx';
import Button from 'client/components/CustomButton/Button.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import useFilters from '../../../hooks/useFilters';
import constants from '../../../utils/constants';
import paymentFormsRepository from '../../../repositories/PaymentForms';

const HomeFormOfPayment = ({ history }) => {
  const [formsOfPayments, setFormsOfPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState('');
  const [newSale, setNewSale] = useState(false);

  const companyId = localStorage.getItem('ID_EMPRESA');
  const pathName = localStorage.getItem('selectedPath');
  const { queryFilter, typeFilter, statusFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const { type, setType, filterByType } = typeFilter;
  const { status, setStatus, filterByStatus } = statusFilter;

  useEffect(() => {
    if (!!companyId) {
      loadPaymentForms();
    }
    if (pathName === 'client/NovaVenda') {
      setNewSale(true);
    }
  }, []);

  const loadPaymentForms = async () => {
    setLoading(true);
    try {
      const paymentForms =
        await paymentFormsRepository.getAllByCompanySortedByDescription(
          companyId
        );
      setFormsOfPayments(paymentForms);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as formas de pagamento. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateChange = async (form) => {
    setLoading(true);
    try {
      await paymentFormsRepository.update(form.original.id, {
        IsActive: !form.original.IsActive,
      });
      loadPaymentForms();
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao atualizar a forma de pagamento. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (formsOfPayment) => {
    const searchQuery = [formsOfPayment['Desciption']];
    return (
      filterByQuery(searchQuery) &&
      filterByType('TypeOfPayment', formsOfPayment) &&
      filterByStatus('IsActive', formsOfPayment)
    );
  };

  const handleModal = async (id) => {
    setId(id);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const paymentMethodCardColors = {
    Dinheiro: 'green',
    Cheque: 'blue',
    'Cartão de Crédito': 'red',
    'Cartão de Débito': '#FF4500',
    'Crédito Loja': 'purple',
    'Vale Alimentação': 'darkcyan',
    'Vale Refeição': 'crimson',
    'Vale Presente': '#00CED1',
    'Vale Combustível': 'brown',
    'Boleto Bancário': 'gold',
    'Depósito Bancário': 'darkblue',
    'Pagamento Instantâneo (PIX)': 'midnightblue',
    'Transferência bancária, Carteira Digital': 'goldenrod',
    'Programa de fidelidade, Cashback, Crédito Virtual': 'maroon',
  };

  return (
    <Card
      content={
        <div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            id="reviewHeader"
            style={{ marginTop: '10px' }}
          >
            <div className="">
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso"
                onClick={() =>
                  !!companyId && newSale
                    ? setModal(true)
                    : !companyId
                    ? setNoCompanyModal(true)
                    : history.push(constants.ROUTES.PAYMENT_FORM)
                }
              >
                + Nova Forma
              </button>
            </div>
            <div
              className="col-xs-12 col-sm-8 col-md-4 col-lg-4"
              id="review-search"
            >
              <i
                id="iconepesquisar"
                style={{ cursor: 'pointer' }}
                className="fa fa-search"
              />
              <input
                type="text"
                style={{ paddingLeft: '30px' }}
                className="form-control"
                placeholder="Pesquisa por Forma de Pagamento"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <strong>Tipo: </strong>
              </span>
              <select
                className="form-control foco-input"
                name="searchStatus"
                value={type}
                onChange={(e) => setType(e.target.value)}
                style={{ marginLeft: '10px' }}
              >
                <option value="">Todos</option>
                <option value="Dinheiro">Dinheiro</option>
                <option value="Cheque">Cheque</option>
                <option value="Cartão de Crédito">Cartão de Crédito</option>
                <option value="Cartão de Débito">Cartão de Débito</option>
                <option value="Crédito Loja">Crédito Loja</option>
                <option value="Vale Presente">Vale Presente</option>
                <option value="Boleto Bancário">Boleto Bancário</option>
                <option value="Outros">Outros</option>
              </select>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '10px',
              }}
            >
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <strong>Status: </strong>
              </span>
              <select
                className="form-control foco-input"
                name="searchStatus"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                style={{ marginLeft: '10px' }}
              >
                <option value="">Ambos</option>
                <option value="1">Ativo</option>
                <option value="0">Inativo</option>
              </select>
            </div>
          </div>
          <div>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={formsOfPayments.filter(handleFilters)}
              columns={[
                {
                  Header: 'Forma de Pagamento',
                  accessor: 'Desciption',
                  headerClassName: 'text-left',
                  width: 220,
                },
                {
                  Header: 'Tipo',
                  accessor: 'TypeOfPayment',
                  headerClassName: 'text-left',
                  width: 300,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor:
                          paymentMethodCardColors[props.value] || 'grey',
                      }}
                    >
                      {props.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Status',
                  accessor: 'IsActive',
                  headerClassName: 'text-left',
                  width: 100,
                  Cell: (props) => (
                    <div>
                      <Toggle
                        checked={String(props.value) === '0' ? false : true}
                        name="ativo"
                        style={{ position: 'fixed' }}
                        onClick={() => handleUpdateChange(props)}
                        readOnly
                      />
                    </div>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'Actions',
                  headerClassName: 'text-left',
                  filterable: false,
                  width: 100,
                  Cell: (props) =>
                    !!companyId && newSale ? (
                      <FontAwesomeIcon
                        title="Editar"
                        cursor="pointer"
                        style={{
                          height: '1.5em',
                          width: '1.5em',
                          color: 'black',
                        }}
                        icon={faEdit}
                        onClick={() => handleModal(props.original.id)}
                      />
                    ) : !companyId ? (
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="false"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setNoCompanyModal(true)}
                      />
                    ) : (
                      <Link
                        to={{ pathname: `payment-form#${props.original.id}` }}
                      >
                        <FontAwesomeIcon
                          title="Editar"
                          cursor="pointer"
                          style={{
                            height: '1.5em',
                            width: '1.5em',
                            color: 'black',
                          }}
                          icon={faEdit}
                        />
                      </Link>
                    ),
                },
              ]}
              defaultPageSize={10}
              className=""
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'IsActive',
                  desc: true,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
          <Modal
            dialogClassName="modal-formProduto"
            show={modal}
            onHide={() => setModal(false)}
            animation={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <strong></strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormPayment
                closeModal={() => closeModal()}
                reloadInfo={() => loadPaymentForms()}
                modal={true}
                nativeModal={true}
                id={id}
              />
            </Modal.Body>
          </Modal>
        </div>
      }
    />
  );
};

export default withRouter(HomeFormOfPayment);
