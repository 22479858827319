import React, { useEffect } from "react";
import { Grid, Row, Col } from "react-bootstrap";

import HomeFormOfPayment from './FormOfPayment'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const FormOfPayment = () => {

  useEffect(() => {
    if (localStorage.getItem('selectedPath') === "client/NovaVenda") {
      localStorage.setItem('selectedPath', 'client/NovaVenda')
    } else {
      localStorage.setItem('selectedPath', 'client/payment-forms')
    }
  }, []);


  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <BreadCrumb data={['Início', 'Cadastros', 'Vendas', 'Forma de Pagamento']}
              path={['home', null, null, null]} />
            <HomeFormOfPayment />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default FormOfPayment