import React from 'react';
import { addDays, format } from 'date-fns';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { currency } from 'client/components/ToNormalize/ToNormalize';

const PartsRequisitionTable = ({
  loading,
  partsRequisitionList,
  pageLimit,
  setPageLimit,
  currentPage,
  setCurrentPage,
  totalPages,
  setCancelModal,
}) => {
  function getBackgroundColorByType(type) {
    const backgroundColors = {
      Entregue: '#5cb85c',
      Cancelado: '#d9534f',
      Orçamento: '#363636',
      Venda: 'purple',
      PDV: '#ffa834',
      'Ordem de Serviço': '#00008B',
    };

    return backgroundColors[type];
  }

  return (
    <div>
      <ReactTable
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          marginTop: 20,
        }}
        data={partsRequisitionList}
        columns={[
          {
            Header: 'Data',
            accessor: 'date',
            width: 120,
            Cell: (props) =>
              format(addDays(new Date(props.value), 1), 'dd/MM/yyyy'),
          },
          {
            Header: 'Tipo',
            accessor: 'type',
            width: 130,
            Cell: (props) => {
              return (
                <button
                  className="admin-custom-label"
                  style={{
                    backgroundColor: getBackgroundColorByType(props.value),
                  }}
                >
                  {props.value}
                </button>
              );
            },
          },
          {
            Header: 'Venda',
            accessor: 'saleCode',
            width: 90,
          },
          {
            Header: 'Cliente',
            accessor: 'customerName',
            width: 250,
          },
          {
            Header: 'Valor',
            accessor: 'value',
            Cell: (props) => currency(props.value),
          },
          {
            Header: 'Funcionário',
            accessor: 'employeeName',
            width: 250,
          },
          {
            Header: 'Status',
            accessor: 'status',
            width: 130,
            Cell: (props) => {
              return (
                <button
                  className="admin-custom-label"
                  style={{
                    backgroundColor: getBackgroundColorByType(props.value),
                  }}
                >
                  {props.value}
                </button>
              );
            },
          },
          {
            Header: 'Ações',
            accessor: 'id',
            Cell: (props) => (
              <div
                style={{
                  display: 'flex',
                  gap: 3,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Link
                  to={{ pathname: `part-requisition#${props.value}` }}
                  title="Visualizar"
                >
                  <FontAwesomeIcon
                    color="black"
                    size="lg"
                    cursor="pointer"
                    icon={faEye}
                  />
                </Link>
                {props.original.status === 'Cancelado' ? null : (
                  <FontAwesomeIcon
                    size="lg"
                    color="red"
                    cursor="pointer"
                    icon={faWindowClose}
                    onClick={() => setCancelModal(props.value)}
                  />
                )}
              </div>
            ),
          },
        ]}
        pageSize={pageLimit}
        page={currentPage}
        onPageChange={setCurrentPage}
        manual
        onPageSizeChange={(value) => {
          setPageLimit(value);
        }}
        pages={totalPages}
        loading={loading}
        showPagination={true}
        defaultPageSize={10}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhuma requisição de peças encontrada"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </div>
  );
};

export default PartsRequisitionTable;
