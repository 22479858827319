import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import Form from './Form'
import { toastr } from 'react-redux-toastr'

import NFesRepository from '../../../../repositories/NFes'
import NFCesRepository from '../../../../repositories/NFCes'

export default function DisableInvoiceModal({
  onHide,
  onCancel,
  onSuccess,
  show,
  nfceSelected,
}) {
  const [loading, setLoading] = useState(false)

  async function handleSubmit(values) {
    setLoading(true)
    await validations(values)
    setLoading(false)
  }

  async function validations(values) {
    const { justification } = values

    if (!justification)
      return toastr.warning('Preencha o campo de justificativa')

    if (justification.length < 15)
      return toastr.warning(
        'O campo de justificativa deve ter ao menos 15 caracteres.'
      )

    if (nfceSelected?.type === 'NFC-e') {
      await submitNFCe({
        nfceId: nfceSelected?.id,
        justification,
      })
    } else {
      await submitNFe({
        nfeId: nfceSelected?.id,
        justification,
      })
    }
  }

  async function submitNFCe(params) {
    try {
      await NFCesRepository.cancel(params)
      toastr.success('Nota cancelada com sucesso.')
      return onSuccess()
    } catch (err) {
      console.log(err)
      console.log(err.response.data)

      if (err.response?.data.code === -4)
        return toastr.error('Prazo para cancelamento expirado.')

      return toastr.error(
        'Falha na requisição',
        'Por favor, tente novamente mais tarde.'
      )
    }
  }

  async function submitNFe(params) {
    try {
      await NFesRepository.cancel(params)
      toastr.success('Nota cancelada com sucesso.')
      return onSuccess()
    } catch (err) {
      console.log(err.response.data)

      const message = err.response.data?.message

      return toastr.error(message)
    }
  }

  return (
    <Modal show={show} animation>
      <Form
        initialValues={{
          companyId: localStorage.getItem('ID_EMPRESA'),
        }}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        loading={loading}
        nfceSelected={nfceSelected}
      />
    </Modal>
  )
}
