import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faEdit } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

import { getDateOnlyFromDate } from 'utils/dateHelpers';
import useFilters from '../../../hooks/useFilters';
import { useAuth } from 'contexts/auth';

import Button from 'client/components/CustomButton/Button.jsx';
import Card from 'components/Card/Card.jsx';
import massPriceChangeRepository from 'repositories/MassPriceChanges';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany';
import constants from '../../../utils/constants';

const HomeBatchPriceChange = ({ history }) => {
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [priceChanged, setPriceChanged] = useState([]);
  const [dateType, setDateType] = useState('creation');
  const { companyId } = useAuth();
  const { typeFilter, queryFilter, dateFilter, statusFilter } = useFilters();
  const { initialDate, setInitialDate, finalDate, setFinalDate, filterByDate } =
    dateFilter;
  const { status, setStatus, filterByStatus } = statusFilter;

  useEffect(() => {
    if (companyId) getMassPriceChanged();
  }, []);

  const getMassPriceChanged = async () => {
    setLoading(true);
    try {
      const allPriceChanged = await massPriceChangeRepository.getAllByCompany(
        companyId
      );
      setPriceChanged(allPriceChanged);
    } catch (err) {
      toastr.error(
        'Ocorreu ao carregar as movimentações. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  function handleFilter(priceChanged) {
    return (
      filterByDate(
        dateType === 'creation' ? 'createdAt' : 'priceChangeDate',
        priceChanged
      ) && filterByStatus('status', priceChanged)
    );
  }

  return (
    <Card
      content={
        <div id="bills-to-receive-page">
          <header className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <section className="filters-wrapper" style={{ marginTop: '10px' }}>
              <div>
                <ModalAlertCompany
                  show={noCompanyModal}
                  onHide={() => setNoCompanyModal(false)}
                />
                <button
                  className="btn btn-sucesso"
                  onClick={() =>
                    !!companyId
                      ? history.push(constants.ROUTES.BATCH_PRICE_CHANGE)
                      : setNoCompanyModal(true)
                  }
                >
                  + Nova Alteração
                </button>
              </div>

              <div style={{ marginLeft: '15px' }}>
                <span style={{ width: `100%` }}>
                  <strong>Tipo de Data:</strong>
                </span>
                <select
                  className="form-control foco-input"
                  value={dateType}
                  style={{ margin: '0 15px 0 10px', width: '114px' }}
                  onChange={(e) => setDateType(e.target.value)}
                >
                  <option value="creation">Criação</option>
                  <option value="change">Alteração</option>
                </select>
              </div>

              <div>
                <span>
                  <strong>Data Inicial: </strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="date"
                  name="dataInicial"
                  max={finalDate}
                  style={{ margin: '0 15px 0 10px' }}
                  value={initialDate}
                  onChange={(e) => setInitialDate(e.target.value)}
                />
              </div>

              <div>
                <span>
                  <strong>Data Final: </strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="date"
                  name="dataFinal"
                  min={initialDate}
                  style={{ margin: '0 15px 0 10px' }}
                  value={finalDate}
                  onChange={(e) => setFinalDate(e.target.value)}
                />
              </div>
              <div>
                <span>
                  <strong>Status: </strong>
                </span>
                <select
                  className="form-control foco-input"
                  value={status}
                  style={{ margin: '0 15px 0 10px' }}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="">Ambos</option>
                  <option value={false}>Em aberto</option>
                  <option value={true}>Finalizado</option>
                </select>
              </div>
            </section>
          </header>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
              }}
              data={priceChanged.filter(handleFilter)}
              columns={[
                {
                  Header: 'Data de criação',
                  accessor: 'createdAt',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 150,
                  Cell: (props) => (
                    <span>
                      {format(getDateOnlyFromDate(props.value), 'dd/MM/yyyy')}
                    </span>
                  ),
                },
                {
                  Header: 'Alteração',
                  accessor: 'productsQuantity',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 90,
                },
                {
                  Header: 'Responsável',
                  accessor: 'Users.Name',
                  headerClassName: 'text-center',
                  className: 'texto',
                },
                {
                  Header: 'Quantidade de produtos',
                  accessor: 'productsQuantity',
                  headerClassName: 'text-center',
                  className: 'texto',
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 90,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor:
                          props.value === false
                            ? '#ffc40d'
                            : props.value === true
                            ? '#46a546'
                            : '',
                      }}
                    >
                      {props.value ? 'Finalizado' : 'Em Aberto'}
                    </Button>
                  ),
                },
                {
                  Header: 'Data de alteração',
                  accessor: 'priceChangeDate',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 150,
                  Cell: (props) => (
                    <span>
                      {!props.value
                        ? ''
                        : format(
                            getDateOnlyFromDate(props.value),
                            'dd/MM/yyyy'
                          )}
                    </span>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  filterable: false,
                  className: 'texto',
                  width: 100,

                  Cell: (props) => (
                    <Link
                      to={{ pathname: `batch-price-change#${props.value}` }}
                      title="Editar"
                    >
                      <FontAwesomeIcon
                        cursor="pointer"
                        icon={faEdit}
                        className="btn-acoes"
                        style={{
                          height: '1.2em',
                          width: '1.2em',
                          color: 'black',
                        }}
                      />
                    </Link>
                  ),
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'createdAt',
                  desc: true,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhuma movimentação foi encontrada"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </div>
      }
    />
  );
};

export default withRouter(HomeBatchPriceChange);
