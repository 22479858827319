import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/auth';
import Card from '../../../components/Card/Card';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany';
import constants from '../../../utils/constants';
import { toastr } from 'react-redux-toastr';
import SearchInput from 'components/SearchInput';
import useFilters from '../../../hooks/useFilters';
import DownloadXlsButton from 'components/DownloadXlsButton';
import ReactTable from 'react-table';
import Button from 'client/components/CustomButton/Button';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import lossReasonsRepository from '../../../repositories/LossReasons';

function HomeLossReasons({ history }) {
  const [loading, setLoading] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [noCompanyModal, setNoCompanyModal] = useState(false);

  const { queryFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const { companyId } = useAuth();

  useEffect(() => {
    if (!!companyId) {
      loadLossReasons();
    }
  }, []);

  const loadLossReasons = async () => {
    setLoading(true);
    try {
      const { data } = await lossReasonsRepository.getAllByCompany({
        companyId,
      });
      setReasons(data);
    } catch (e) {
      console.log(e);
      toastr.warning(
        'Ocorreu um erro ao buscar os motivos de perda. Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (desc) => {
    const querySearch = [desc['description']];
    return filterByQuery(querySearch);
  };

  const filteredLossReasons = reasons.filter(handleFilters);

  return (
    <Card
      content={
        <div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            id="reviewHeader"
            style={{ marginTop: '10px' }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso"
                onClick={
                  () =>
                    !!companyId
                      ? history.push(constants.ROUTES.NEW_LOSS_REASON)
                      : setNoCompanyModal(true) // ??
                }
              >
                + Novo Motivo de Perda
              </button>
            </div>

            <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
              <SearchInput
                placeholder="Pesquisa por Descrição"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>

            <div style={{ marginLeft: '10px' }}>
              <DownloadXlsButton
                archiveName={`Relatório de Motivos de Perda - ${format(
                  new Date(),
                  'dd/MM/yyyy'
                )}`}
                data={filteredLossReasons}
                disabled={!!!filteredLossReasons.length}
                className="btn btn-export"
                columns={[
                  {
                    name: 'Descriçao',
                    acessor: 'description',
                  },
                  {
                    name: 'Status',
                    acessor: 'status',
                  },
                ]}
              >
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                Exportar .xls
              </DownloadXlsButton>
            </div>
          </div>

          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              data={filteredLossReasons}
              columns={[
                {
                  Header: 'Descrição',
                  accessor: 'description',
                },
                {
                  Header: 'Status',
                  accessor: 'isActive',
                  width: 100,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor: !!props.value ? 'green' : 'red',
                      }}
                    >
                      {!!props.value ? 'Ativo' : 'Inativo'}
                    </Button>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  headerClassName: 'text-left',
                  filterable: false,
                  className: 'texto',
                  width: 100,

                  Cell: (props) => (
                    <div className="table-actions">
                      <Link
                        to={{ pathname: `loss-reason#${props.value}` }}
                        title="Editar"
                      >
                        <FontAwesomeIcon cursor="pointer" icon={faEdit} />
                      </Link>
                    </div>
                  ),
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination
              sortable
              showPaginationTop={false}
              showPaginationBottom
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'Company_Name',
                  desc: false,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhum motivo de perda encontrado"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </div>
      }
    />
  );
}

export default withRouter(HomeLossReasons);
