import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';

export const getBreadcrumbData = (isEditing) => {
  return {
    data: [
      'Início',
      'Cadastros',
      'Vendas',
      'Checklist Personalizado',
      'Correções',
    ],
    path: [
      '/client/home',
      null,
      null,
      null,
      '/client/' + CLIENT_PATH_ROUTES.CORRECTIONS.BASE,
    ],
  };
};

export const optionsIsActiveSelect = [
  {
    label: 'Ambos',
    value: '',
  },
  {
    label: 'Ativo',
    value: true,
  },
  {
    label: 'Inativo',
    value: false,
  },
];
