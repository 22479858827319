import React from 'react'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SearchInput from 'components/SearchInput'
import { format } from 'date-fns'
import constants from 'utils/constants'
import DownloadXlsButton from 'components/DownloadXlsButton'

import '../styles.css'

const InventoryCountFilter = ({
  history,
  setStatus,
  setDateType,
  setInitialDate,
  setFinalDate,
  handleChangeQuery,
  inventoryCountList,
  actualDate,
  initialDate,
  finalDate,
  dataToExport,
  isLoadingExport,
  isExportReady,
  handleLoadDataToExport,
}) => {
  return (
    <>
      <main className="client__inventory-count-page">
        <section className="client__inventory-count-page__filter-row ">
          <button
            className="btn btn-sucesso"
            onClick={() => history.push(constants.ROUTES.NEW_INVENTORY_COUNT)}
          >
            + Novo Inventário/Contagem
          </button>
          <div className="search-input-wrapper">
            <SearchInput
              placeholder="Pesquisa por Descrição"
              onChange={(e) => handleChangeQuery(e.target.value)}
            />
          </div>
          <fieldset>
            <label>Status:</label>
            <select
              onChange={(e) => setStatus(e.target.value)}
              className="form-control foco-input"
            >
              <option value="">Ambos</option>
              <option value="Em Aberto">Em Aberto</option>
              <option value="Finalizada">Finalizada</option>
            </select>
          </fieldset>
        </section>
        <section className="client__inventory-count-page__filter-row ">
          <fieldset>
            <label style={{ width: '100px' }}>Tipo de Data:</label>
            <select
              onChange={(e) => setDateType(e.target.value)}
              className="form-control foco-input"
              style={{
                width: '130px',
              }}
            >
              <option value="createdAt">Criação</option>
              <option value="finishDate">Finalização</option>
            </select>
          </fieldset>

          <fieldset>
            <label style={{ width: '85px' }}>Data Inicial:</label>
            <input
              style={{
                width: '135px',
              }}
              type="date"
              className="form-control foco-input"
              value={initialDate}
              max={actualDate}
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </fieldset>

          <fieldset>
            <label style={{ width: '80px' }}>Data Final:</label>
            <input
              style={{
                width: '135px',
              }}
              type="date"
              className="form-control foco-input"
              value={finalDate}
              max={actualDate}
              min={initialDate}
              onChange={(e) => setFinalDate(e.target.value)}
            />
          </fieldset>

          <fieldset style={{ marginLeft: '10px' }}>
            {!isExportReady ? (
              <button
                className="btn btn-export"
                onClick={() => handleLoadDataToExport()}
                disabled={isLoadingExport}
              >
                {isLoadingExport ? (
                  <span className="fa fa-spinner fa-pulse fa-1x"></span>
                ) : (
                  <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
                )}
                &nbsp;Exportar .xls
              </button>
            ) : (
              <DownloadXlsButton
                archiveName={`Inventário/Contagem - ${format(
                  new Date(),
                  'dd/MM/yyyy'
                )}`}
                data={dataToExport}
                className="btn btn-export export-accounts"
                disabled={!dataToExport.length}
                columns={[
                  {
                    name: 'Criação',
                    acessor: 'createdAt',
                  },
                  {
                    name: 'Descrição',
                    acessor: 'description',
                  },
                  {
                    name: 'Qtd Produtos',
                    acessor: 'qtdItems',
                  },
                  {
                    name: 'Data Finalização',
                    acessor: 'finishDate',
                  },
                  {
                    name: 'Status',
                    acessor: 'status',
                  },
                ]}
              >
                <FontAwesomeIcon
                  color="white"
                  style={{ marginRight: '10px' }}
                  icon={faCloudDownloadAlt}
                />
                Baixar .xls
              </DownloadXlsButton>
            )}
          </fieldset>
        </section>
      </main>
    </>
  )
}

export default InventoryCountFilter
