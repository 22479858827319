import React, { useState, useEffect, useCallback } from 'react';
import ReactTable from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';

import Button from 'client/components/CustomButton/Button.jsx';
import Card from 'components/Card/Card.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import useFilters from '../../../hooks/useFilters';
import constants from '../../../utils/constants';
import profilesRepository from '../../../repositories/Profiles';
import { useAuth } from '../../../contexts/auth';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import { useHistory } from 'react-router-dom';
import { useRouter } from 'v2/hooks/useRouter';

const HomeProfiles = () => {
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [profiles, setProfiles] = useState([]);

  const { companyId } = useAuth();
  const { navigateToClientPage } = useRouter();
  const history = useHistory();

  const { queryFilter, statusFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const { status, setStatus, filterByStatus } = statusFilter;

  useEffect(() => {
    if (companyId) loadProfiles();
  }, [companyId]);

  const loadProfiles = async () => {
    setLoading(true);
    try {
      const profiles = await profilesRepository.getAllByCompany(companyId);
      setProfiles(profiles.data);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os perfis. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (profile) => {
    const querySearch = [profile['description']];
    return filterByQuery(querySearch) && filterByStatus('isActive', profile);
  };

  const handleEditItem = useCallback((profile) => {
    if (profile.companyId === null && profile.description === 'ADMINISTRADOR')
      return toastr.warning('Não é possível editar o perfil de Administrador');
    const profileIdHash = encrypt(profile.id, '@OS-dig:profileId');
    history.push(`/client/${constants.ROUTES.PROFILE}?id=${profileIdHash}`);
  }, []);

  const handlePermissions = useCallback((profile) => {
    if (profile.companyId === null && profile.description === 'ADMINISTRADOR')
      return toastr.warning('Não é possível editar o perfil de Administrador');
    const profileAccessPermissions = encrypt(profile.id, '@OS-dig:profileId');
    navigateToClientPage(
      'ACCESS_PROFILE',
      'FORM_EDIT_RAW',
      profileAccessPermissions
    );
  }, []);

  return (
    <Card
      content={
        <div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            id="reviewHeader"
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso"
                onClick={() =>
                  companyId
                    ? history.push(constants.ROUTES.PROFILE)
                    : setNoCompanyModal(true)
                }
              >
                + Novo Perfil
              </button>
            </div>
            <div
              className="col-xs-12 col-sm-8 col-md-4 col-lg-4"
              id="review-search"
            >
              <i id="iconepesquisar" className="fa fa-search"></i>
              <input
                type="text"
                style={{ paddingLeft: '30px', width: '100%' }}
                className="form-control foco-input"
                placeholder="Pesquisa por Descrição"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <label>
                <strong>Status:</strong>
              </label>
              <select
                className="form-control foco-input"
                onChange={(event) => setStatus(event.target.value)}
                value={status}
              >
                <option value="true">Ativo</option>
                <option value="false">Inativo</option>
                <option value="">Ambos</option>
              </select>
            </div>
          </div>
          <div>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={profiles.filter(handleFilters)}
              columns={[
                {
                  Header: 'Descrição',
                  accessor: 'description',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Status',
                  accessor: 'isActive',
                  headerClassName: 'text-left',
                  width: 140,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor: props.value == 1 ? 'green' : 'red',
                      }}
                    >
                      {props.value == 1 ? 'Ativo' : 'Inativo'}
                    </Button>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  headerClassName: 'text-left',
                  filterable: false,
                  width: 125,
                  Cell: (props) => (
                    <>
                      <FontAwesomeIcon
                        title="Editar Cargo"
                        cursor="pointer"
                        icon={faEdit}
                        style={{ width: '1.5em', height: '1.5em' }}
                        onClick={() => handleEditItem(props.original)}
                      />

                      <FontAwesomeIcon
                        title="Permissões de Acesso do Perfil"
                        cursor="pointer"
                        icon={faUserShield}
                        style={{ width: '1.5em', height: '1.5em' }}
                        onClick={() => handlePermissions(props.original)}
                      />
                    </>
                  ),
                },
              ]}
              defaultPageSize={10}
              className=""
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'description',
                  desc: false,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </div>
      }
    />
  );
};

export default withRouter(HomeProfiles);
