import React from "react";
import { Grid, Row } from "react-bootstrap";

import HomeLossReasons from './LossReasons'
import constants from '../../../utils/constants'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const LossReasons = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb 
            data={['Início', 'Cadastro', 'Vendas', 'Motivos de Perda']}
            path={[constants.ROUTES.HOME, null, null, null]} 
          />
          <HomeLossReasons />
        </Row>
      </Grid>
    </div>
  )
}

export default LossReasons
