import React from 'react'
import SearchInput from 'components/SearchInput'
import constants from 'utils/constants'
import '../styles.css'
import { addDays, format } from 'date-fns'

const CustomerCreditFilters = ({
  setRelease,
  handleChangeQuery,
  setInitialDate,
  setFinalDate,
  initialDate,
  finalDate,
  setNewCustomerCredit,
}) => {
  return (
    <div>
      <div className="customer-credit__filters">
        <div
          className="customer-credit__filters-rows"
          style={{ marginTop: 10 }}
        >
          <button
            className="btn btn-sucesso"
            onClick={() => setNewCustomerCredit(true)}
          >
            + Novo Lançamento
          </button>

          <div className="search-input-wrapper">
            <SearchInput
              placeholder="Pesquisa por CPF/CNPJ ou Nome/Nome Fantasia"
              onChange={(e) => handleChangeQuery(e.target.value)}
            />
          </div>

          <fieldset>
            <span>
              <strong>Lançamento:</strong>
            </span>
            <select
              onChange={(e) => setRelease(e.target.value)}
              className="form-control foco-input"
            >
              <option value="">Ambos</option>
              <option value="Entrada">Entrada</option>
              <option value="Saída">Saída</option>
            </select>
          </fieldset>
        </div>

        <div className="customer-credit__filters-rows">
          <fieldset>
            <span>
              <strong>Data Inicial: </strong>
            </span>
            <input
              type="date"
              className="form-control foco-input"
              onChange={(e) => setInitialDate(e.target.value)}
              max={
                finalDate
                  ? format(addDays(new Date(finalDate), 1), 'yyyy-MM-dd')
                  : format(new Date(), 'yyyy-MM-dd')
              }
            />
          </fieldset>

          <fieldset>
            <span>
              <strong>Data Final: </strong>
            </span>
            <input
              type="date"
              className="form-control foco-input"
              onChange={(e) => setFinalDate(e.target.value)}
              max={format(new Date(), 'yyyy-MM-dd')}
              min={
                initialDate
                  ? format(addDays(new Date(initialDate), 1), 'yyyy-MM-dd')
                  : null
              }
            />
          </fieldset>
        </div>
      </div>
    </div>
  )
}

export default CustomerCreditFilters
