import React, { useState } from 'react';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import AlertModal from 'components/AlertModal/AlertModal';
import ItemsModal from './ItemsModal';
import hangtagRepository from 'v2/repositories/HangtagRepository';
import '../styles.css';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { useAuth } from 'contexts/auth';

export default function Main() {
  const { company } = useAuth();
  const isWholesale =
    company?.companyConfig.sellingPriceType === 'Preço Atacado e Varejo';

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hangtagModel, setHangtagModel] = useState('');
  const [printBarCode, setPrintBarCode] = useState('byBarCode');
  const [showSalePriceType, setShowSalePriceType] = useState('retail');
  const [allowPrintWithEmptyBarCodeModal, setAllowPrintWithEmptyBarCodeModal] =
    useState(false);
  const [allowPrintWithEmptyBrandModal, setAllowPrintWithEmptyBrandModal] =
    useState(false);
  const [isItemsModalOpen, setIsItemsModalOpen] = useState(false);

  const handleAddItems = (addedItems) => {
    const newItems = [...items];

    addedItems.forEach((addedItem) => {
      const index = newItems.findIndex(
        (item) => item.productId === addedItem.productId
      );

      if (index !== -1) {
        newItems[index].quantity += addedItem.quantity;
      } else {
        newItems.push(addedItem);
      }
    });

    setItems(newItems);
    setIsItemsModalOpen(false);
  };

  const handleDeleteItem = (index, productCode) => {
    const newItems = [...items];

    newItems.splice(index, 1);

    setItems(newItems);

    toastr.success(
      'Produto removido com sucesso',
      `O produto ${productCode} foi excluído com sucesso`
    );
  };

  const handleChangeQuantity = (e, index) => {
    const newItems = [...items];
    if (+e.target.value > 999) {
      return;
    }
    newItems[index].quantity = +e.target.value;

    setTimeout(() => {
      document.getElementById(`hangtag-item-quantity-${index}`).focus();
    }, 50);

    setItems(newItems);
  };

  const handlePrintTags = () => {
    setLoading(true);

    const hasEmptyBarCode = items.some(
      (item) => !item.barCode || item.barCode.trim() === ''
    );

    const hasEmptyAlternativeCode = items.some(
      (item) => !item.alternativeCode || item.alternativeCode.trim() === ''
    );

    const hasEmptyInternalCode = items.some(
      (item) => !item.code || item.code.trim() === ''
    );

    const hasEmptyBrand = items.some((item) => !item.Brand);

    let shouldPrintBarCode = false;
    let shouldPrintBrand = false;

    switch (printBarCode) {
      case 'byBarCode':
        shouldPrintBarCode = !hasEmptyBarCode;
        break;
      case 'byInternalCode':
        shouldPrintBarCode = !hasEmptyInternalCode;
        break;
      case 'byAlternativeCode':
        shouldPrintBarCode = !hasEmptyAlternativeCode;
        break;
      default:
        shouldPrintBarCode = true;
    }

    shouldPrintBrand = !hasEmptyBrand;

    if (!shouldPrintBarCode) {
      setAllowPrintWithEmptyBarCodeModal(true);
    }

    if (!shouldPrintBrand) {
      setAllowPrintWithEmptyBrandModal(true);
    }

    if (shouldPrintBarCode && shouldPrintBrand) {
      printTags();
    }
  };

  const printTags = async () => {
    const formatedItems = items.map(({ productId, quantity }) => {
      return {
        productId,
        quantity,
      };
    });
    const hasInvalidQuantity = formatedItems.some(
      ({ quantity }) => quantity === 0
    );

    if (hasInvalidQuantity) {
      toastr.warning(
        'Quantidade inválida',
        'Insira uma quantidade maior que 0 ou exclua o produto'
      );
      return;
    }

    try {
      const tagToPrint = await hangtagRepository.printTags({
        formatedItems,
      });

      if (tagToPrint) {
        window.open(
          window.location.origin +
            `/print/hangtag?barcode=${printBarCode}&price=${showSalePriceType}&model=${hangtagModel}&id=${tagToPrint.id}`,
          '_blank'
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const emptyBarCodeSubmit = () => {
    setAllowPrintWithEmptyBarCodeModal(false);
    if (!allowPrintWithEmptyBrandModal) {
      printTags();
    }
  };

  const emptyBrandSubmit = () => {
    setAllowPrintWithEmptyBrandModal(false);
    if (!allowPrintWithEmptyBarCodeModal) {
      printTags();
    }
  };

  const handlePrintBarCode = (value) => {
    setPrintBarCode(value);
  };

  const getCodeTypeMessage = (printBarCode) => {
    switch (printBarCode) {
      case 'byBarCode':
        return 'código de barras';
      case 'byInternalCode':
        return 'código interno';
      case 'byAlternativeCode':
        return 'código alternativo';
      default:
        return '';
    }
  };

  return (
    <main className="hang-tag-wrapper">
      <section className="hang-tag-filter-row">
        <button
          className="btn btn-sucesso new-hang-tag-config"
          onClick={() => setIsItemsModalOpen(true)}
          style={{ fontSize: '12px' }}
        >
          + Adicionar Item
        </button>
        <div>
          <span className="typography__caption">
            <strong>Modelo de etiqueta:</strong>
          </span>
          <select
            value={hangtagModel}
            onChange={(e) => setHangtagModel(e.target.value)}
            className="form-control foco-input typography__caption"
          >
            <option value={'product'}>Por produto</option>
            <option value={'shelf'}>Prateleira</option>
          </select>
        </div>
        <div>
          <span className="typography__caption">
            <strong>Imprimir Código de Barras:</strong>
          </span>
          <select
            value={printBarCode}
            onChange={(e) => handlePrintBarCode(e.target.value)}
            className="form-control foco-input typography__caption"
          >
            <option value={'byBarCode'}>Sim - Código de Barras</option>
            <option value={'byInternalCode'}>Sim - Código Interno</option>
            <option value={'byAlternativeCode'}>
              Sim - Código Alternativo
            </option>
            <option value={'false'}>Não</option>
          </select>
        </div>
        <div>
          <span className="typography__caption">
            <strong>Exibir R$ de Venda:</strong>
          </span>
          <select
            value={showSalePriceType}
            onChange={(e) => setShowSalePriceType(e.target.value)}
            className="form-control foco-input typography__caption"
          >
            {isWholesale ? (
              <>
                <option value={'retail'}>Sim - Varejo</option>
                <option value={'wholesale'}>Sim - Atacado</option>
                <option value={'both'}>Sim - Ambos</option>
                <option value={false}>Não</option>
              </>
            ) : (
              <>
                <option value={'retail'}>Sim</option>
                <option value={false}>Não</option>
              </>
            )}
          </select>
        </div>
        <button
          className="btn btn-print-tag new-hang-tag-config"
          disabled={items.length === 0}
          onClick={() => handlePrintTags()}
          style={{ fontSize: '12px' }}
        >
          <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
          Imprimir etiquetas
        </button>
      </section>
      <span
        style={{
          color: '#D9534F',
          textAlign: 'flex-end',
          alignSelf: 'flex-end',
          marginLeft: 'auto',
          fontSize: '13px',
        }}
      >
        Apenas etiquetas PIMACO 6081/6181/6281
      </span>
      <div>
        <ReactTable
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            width: '100%',
            fontSize: '12px',
          }}
          data={items}
          columns={[
            {
              Header: 'Cód. Interno',
              accessor: 'code',
              width: 90,
              Cell: ({ value }) => (
                <div title={value}>
                  {value.length > 6 ? `${value.substring(0, 6)}...` : value}
                </div>
              ),
            },
            {
              Header: 'Ref. Fabricante',
              accessor: 'Manufacturer_Ref',
              width: 100,
            },
            {
              Header: 'Descrição',
              accessor: 'description',
            },
            {
              Header: 'Cód. de barras',
              accessor: 'barCode',
              width: 120,
            },
            {
              Header: 'Família',
              accessor: 'Family',
              width: 140,
            },
            {
              Header: 'Marca',
              accessor: 'Brand',
              width: 140,
            },
            {
              Header: 'QTD',
              accessor: 'quantity',
              width: 70,
              height: 20,
              Cell: (props) => (
                <input
                  id={`hangtag-item-quantity-${props.index}`}
                  className="form-control foco-input"
                  type="number"
                  style={{
                    fontSize: '12px',
                    maxWidth: '50px',
                    textAlign: 'center',
                    padding: '0',
                  }}
                  value={props.value}
                  onChange={(e) => handleChangeQuantity(e, props.index)}
                />
              ),
            },
            {
              Header: 'Cód. Alternativo',
              accessor: 'alternativeCode',
              width: 120,
            },
            {
              Header: isWholesale ? 'R$ Venda - Varejo' : 'R$ Venda',
              accessor: 'unitValue',
              width: 120,
              Cell: (props) => <a>{currency(props.value)}</a>,
            },
            ...(isWholesale
              ? [
                  {
                    Header: 'R$ Venda - Atacado',
                    accessor: 'salePriceWholesale',
                    width: 120,
                    Cell: (props) => <a>{currency(props.value)}</a>,
                  },
                ]
              : []),
            {
              Header: 'Ações',
              accessor: 'code',
              width: 50,
              Cell: (props) => (
                <FontAwesomeIcon
                  title="Excluir"
                  cursor="pointer"
                  style={{
                    height: '1.5em',
                    width: '1.5em',
                    color: 'red',
                    textAlign: 'center',
                  }}
                  icon={faTrashAlt}
                  onClick={() => {
                    handleDeleteItem(props.index, props.value);
                  }}
                />
              ),
            },
          ]}
          defaultPageSize={10}
          loading={loading}
          showPagination
          sortable
          showPaginationTop={false}
          showPaginationBottom
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          previousText="Anterior"
          nextText="Próximo"
          loadingText="Carregando..."
          noDataText="Não há informação"
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />
      </div>

      {isItemsModalOpen && (
        <ItemsModal
          onCancel={() => setIsItemsModalOpen(false)}
          onSubmit={handleAddItems}
        />
      )}

      {allowPrintWithEmptyBarCodeModal && printBarCode && (
        <AlertModal
          title="Confirmação de impressão"
          show={allowPrintWithEmptyBarCodeModal}
          backdrop={false}
          message={
            <>
              <span>
                <strong>
                  Existem produtos sem {getCodeTypeMessage(printBarCode)}{' '}
                  adicionados
                </strong>
              </span>
              <br />
              <br />
              <span>
                Está ativo a opção de imprimir{' '}
                {getCodeTypeMessage(printBarCode)}, mas existem itens
                adicionados que não possuem a informação. Deseja imprimir a
                etiqueta mesmo assim? As etiquetas desses produtos não terão{' '}
                {getCodeTypeMessage(printBarCode)}.
              </span>
            </>
          }
          onCancel={() => {
            setAllowPrintWithEmptyBarCodeModal(false);
            setAllowPrintWithEmptyBrandModal(false);
            setLoading(false);
          }}
          onSubmit={() => emptyBarCodeSubmit()}
          onHide={() => {
            setAllowPrintWithEmptyBarCodeModal(false);
            setLoading(false);
          }}
        />
      )}

      {allowPrintWithEmptyBrandModal && (
        <AlertModal
          title="Confirmação de impressão"
          show={allowPrintWithEmptyBrandModal}
          backdrop={false}
          message={
            <>
              <span>
                <strong>Existem produtos sem marca adicionados</strong>
              </span>
              <br />
              <br />
              <span>
                São impressas as marcas dos produtos na etiqueta. Se desejar
                continuar a impressão, os produtos sem marca apresentarão um
                espaço vazio. Deseja imprimir a etiqueta mesmo assim?
              </span>
            </>
          }
          onCancel={() => {
            setAllowPrintWithEmptyBrandModal(false);
            setAllowPrintWithEmptyBarCodeModal(false);
            setLoading(false);
          }}
          onSubmit={() => emptyBrandSubmit()}
          onHide={() => {
            setAllowPrintWithEmptyBrandModal(false);
            setLoading(false);
          }}
        />
      )}
    </main>
  );
}
