import React from "react";
import { Grid, Row } from "react-bootstrap";

import BreadCrumb from 'components/BreadCrumb/BreadCrumb'
import constants from '../../../utils/constants'
import PrismaBox from './PrismaBox'

const PrismaBoxex = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <BreadCrumb
            data={['Início', 'Cadastro', 'Vendas', 'Prisma/Box']}
            path={[constants.ROUTES.HOME, null, null, null]}
          />
          <PrismaBox />
        </Row>
      </Grid>
    </div>
  );
}
export default PrismaBoxex