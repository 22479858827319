import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import HomeFamilies from "./HomeFamilies";
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const Families = () => {
  localStorage.setItem('selectedPath', 'client/Familia')

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <BreadCrumb data={['Início', 'Cadastros', 'Produtos/Serviços', 'Famílias']}
                        path={['home', null, null, 'Familia']}/>
            <HomeFamilies />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default Families