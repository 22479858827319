import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';

import DownloadXlsButton from '../../../components/DownloadXlsButton';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

import Card from 'components/Card/Card.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import { kmMask } from 'client/components/ToNormalize/ToNormalize';
import revisionsRepository from '../../../repositories/Revisions';
import useFilters from '../../../hooks/useFilters';
import constants from '../../../utils/constants';

import './styles.css';

const HomeRevisions = ({ history }) => {
  const [revisions, setRevisions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);

  const companyId = localStorage.getItem('ID_EMPRESA');
  const { queryFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;

  useEffect(() => {
    if (!!companyId) {
      loadRevisions();
    }
  }, []);

  const loadRevisions = async () => {
    setLoading(true);

    try {
      const revisions = await revisionsRepository.getAllByCompany(companyId);
      setRevisions(revisions);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as revisões. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (review) => {
    const searchQuery = [review['Description']];
    return filterByQuery(searchQuery);
  };

  return (
    <Card
      content={
        <div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            id="reviewHeader"
            style={{ marginTop: '10px' }}
          >
            <div className="">
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                id="btn-nova-revisao"
                className="btn btn-sucesso"
                onClick={() =>
                  companyId
                    ? history.push(constants.ROUTES.REVIEW)
                    : setNoCompanyModal(true)
                }
              >
                + Nova Revisão
              </button>
            </div>
            <div
              className="col-xs-12 col-sm-8 col-md-4 col-lg-4"
              id="review-search"
            >
              <i id="iconepesquisar" className="fa fa-search"></i>
              <input
                type="text"
                value={query}
                style={{ paddingLeft: '30px', width: '100%' }}
                className="form-control foco-input"
                placeholder="Pesquisar por Descrição"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>

            <DownloadXlsButton
              archiveName={`Revisões - ${format(
                new Date(),
                'dd-MM-yyyy-HH-mm'
              )}`}
              data={revisions.filter(handleFilters).map((revision) => ({
                ...revision,
                km: revision?.Km ? kmMask(revision.Km.toString()) : '',
              }))}
              className="btn btn-export"
              disabled={loading || !revisions.filter(handleFilters).length}
              columns={[
                {
                  name: 'Código',
                  acessor: 'Code',
                },
                {
                  name: 'Descrição',
                  acessor: 'Description',
                },
                {
                  name: 'KM',
                  acessor: 'km',
                },
                {
                  name: 'Meses',
                  acessor: 'Months',
                },
                {
                  name: 'Observação',
                  acessor: 'Comments',
                },
              ]}
            >
              <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
              Exportar .xls
            </DownloadXlsButton>
          </div>

          <div>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={revisions.filter(handleFilters)}
              columns={[
                {
                  Header: 'Código',
                  id: 'Code',
                  accessor: (props) => Number(props.Code),
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Descrição',
                  accessor: 'Description',
                  headerClassName: 'text-left',
                  width: 250,
                },
                {
                  Header: 'KM',
                  id: 'Km',
                  accessor: (props) => Number(props.Km),
                  headerClassName: 'text-left',
                  Cell: (props) => kmMask(props.value.toString()),
                },
                {
                  Header: 'Meses',
                  id: 'Months',
                  accessor: (props) => Number(props.Months),
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  headerClassName: 'text-left',
                  filterable: false,
                  Cell: (props) =>
                    !!props.original.Company_id && (
                      <Link to={{ pathname: `review#${props.value}` }}>
                        <FontAwesomeIcon
                          title="Editar"
                          cursor="pointer"
                          style={{
                            height: '1.5em',
                            width: '1.5em',
                            color: 'black',
                          }}
                          icon={faEdit}
                        />
                      </Link>
                    ),
                },
              ]}
              defaultPageSize={10}
              className=""
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'Description',
                  desc: false,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </div>
      }
    />
  );
};

export default withRouter(HomeRevisions);
