import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';
import Card from 'components/Card/Card.jsx';
import SearchInput from 'components/SearchInput';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';

import { useAuth } from 'contexts/auth';
import constants from 'utils/constants';
import useFilters from 'hooks/useFilters';
import discountGroupRepository from 'repositories/DiscountGroups';

import {
  faBoxOpen,
  faEdit,
  faTrashAlt,
  faTools,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';

const DiscountGroupTables = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(0);

  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);

  const [discountTables, setDiscountTables] = useState([]);
  const [productToDeleteId, setProductToDeleteId] = useState(0);

  const { queryFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;

  const { companyId } = useAuth();

  useEffect(() => {
    loadDiscountTables();
  }, []);

  async function loadDiscountTables() {
    try {
      setLoading(true);

      const discountGroups = await discountGroupRepository.getAll(companyId);

      setDiscountTables(discountGroups);
    } catch (err) {
      toastr.warning(
        'Falha ao buscar tabelas',
        'Ocorreu uma falha ao buscar as tabelas de preços diferenciados, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleDelete() {
    try {
      setLoading(true);

      if (productToDeleteId === 0)
        throw new Error('Nenhum produto foi selecionado para ser deletado');

      await discountGroupRepository.deleteGroup(productToDeleteId);

      toastr.success(
        'Tabela deletada',
        'Tabela de preços diferenciados deletado com sucesso'
      );

      loadDiscountTables();
    } catch (err) {
      toastr.error(
        'Erro na exclusão',
        'Ocorreu um erro ao deletar a tabela de preços diferenciados, tente novamente'
      );
    } finally {
      setShowDeleteGroupModal(false);
      setLoading(false);
    }
  }

  function handleFilter(discountGroup) {
    const querySearch = [discountGroup['name']];

    return filterByQuery(querySearch);
  }

  return (
    <>
      <Card
        content={
          <div>
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              id="reviewHeader"
              style={{ marginTop: '10px' }}
            >
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso"
                onClick={() =>
                  companyId
                    ? history.push(constants.ROUTES.NEW_DISCOUNT_GROUPS)
                    : setNoCompanyModal(true)
                }
              >
                + Nova Tabela de Preços
              </button>
              <div
                className="col-xs-12 col-sm-5 col-md-5 col-lg-5"
                style={{ marginLeft: '0px' }}
              >
                <SearchInput
                  placeholder="Pesquisa por Descrição"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
            <ReactTable
              style={{ width: '70%', fontWeight: 'bold', textAlign: 'center' }}
              data={discountTables.filter(handleFilter)}
              columns={[
                {
                  Header: 'Descrição',
                  accessor: 'name',
                  width: 450,
                },
                {
                  Header: 'Qtd. Produtos',
                  accessor: 'productsQuantity',
                },
                {
                  Header: 'Qtd. Serviços',
                  accessor: 'servicesQuantity',
                },
                {
                  accessor: 'id',
                  filterable: false,
                  className: 'texto',
                  style: { display: 'flex', justifyContent: 'center' },
                  Cell: (props) =>
                    props.original.id && (
                      <>
                        <Link
                          to={{
                            pathname: `discount-group-table#${props.value}`,
                          }}
                          title="Editar"
                        >
                          <FontAwesomeIcon
                            cursor="pointer"
                            icon={faEdit}
                            style={{ width: '1.5em', height: '1.5em' }}
                          />
                        </Link>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              `discount-group-products#${props.value}`
                            );
                          }}
                          to={{
                            pathname: `discount-group-products#${props.value}`,
                          }}
                          title="Lista de produtos"
                        >
                          <FontAwesomeIcon
                            cursor="pointer"
                            icon={faBoxOpen}
                            style={{ width: '1.5em', height: '1.5em' }}
                          />
                        </Link>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              `discount-group-services#${props.value}`
                            );
                          }}
                          to={{
                            pathname: `discount-group-services#${props.value}`,
                          }}
                          title="Lista de serviços"
                        >
                          <FontAwesomeIcon
                            cursor="pointer"
                            icon={faTools}
                            style={{ width: '1.5em', height: '1.5em' }}
                          />
                        </Link>
                        <FontAwesomeIcon
                          title="Excluir"
                          cursor="pointer"
                          style={{
                            height: '1.5em',
                            width: '1.5em',
                            color: '#bd362f',
                            marginRight: '5px',
                          }}
                          icon={faTrashAlt}
                          onClick={() => {
                            setProductToDeleteId(props.value);
                            setShowDeleteGroupModal(true);
                          }}
                        />
                      </>
                    ),
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination
              sortable
              showPaginationTop={false}
              showPaginationBottom
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              pages={pages}
              manual
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhuma tabela de preços diferenciados encontrada"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        }
      />
      <Modal
        show={showDeleteGroupModal}
        onHide={() => setShowDeleteGroupModal(false)}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>OS Digital</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>
            Você tem certeza que deseja excluir a tabela de preços
            diferenciados?
          </strong>
          <br />
          <br />
          <p>
            O processo é irreversível. Ao exlcuir a tabela, todos os vínculos de
            produtos e serviços serão automaticamente deletados.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              bsStyle="danger"
              disabled={loading}
              name="Não"
              onClick={() => setShowDeleteGroupModal(false)}
              fill
            >
              Não
            </Button>
            <Button
              bsStyle="info"
              disabled={loading}
              name="Sim"
              fill
              onClick={handleDelete}
            >
              Sim{' '}
              <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withRouter(DiscountGroupTables);
