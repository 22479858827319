import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import Toggle from 'react-toggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';
import Card from 'components/Card/Card.jsx';
import { Modal } from 'react-bootstrap';

import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import paymentConditionsRepository from '../../../repositories/PaymentConditions';
import useFilters from '../../../hooks/useFilters';
import SearchInput from 'components/SearchInput';

import { useAuth } from '../../../contexts/auth';
import constants from '../../../utils/constants';
import { percentage } from 'client/components/ToNormalize/ToNormalize';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { SelectMulti } from 'v2/components/Select/Multi';
import useDebounce from 'hooks/useDebounce';
import { RegisterPaymentCondition } from './RegisterPaymentCondition';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';

const HomePaymentCondition = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState('');
  const [newSale, setNewSale] = useState(false);

  const { companyId } = useAuth();
  const pathName = localStorage.getItem('selectedPath');

  const [query, setQuery] = useState('');
  const [status, setStatus] = useState('');
  const [hasEntry, setHasEntry] = useState('');
  const [hasFee, setHasFee] = useState('');

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  useEffect(() => {
    if (companyId) {
      loadPaymentConditions();
    }
    if (pathName === 'client/NovaVenda') {
      setNewSale(true);
    }
  }, []);

  useEffect(() => {
    if (companyId) {
      loadPaymentConditions();
    }

    if (pathName === 'client/NovaVenda') {
      setNewSale(true);
    }
  }, [currentPage, pageLimit, queryDebounced, hasEntry, hasFee, status]);

  const loadPaymentConditions = async () => {
    setLoading(true);
    try {
      const paymentConditions =
        await paymentConditionsRepository.getAllPaginated({
          limit: pageLimit,
          page: currentPage + 1,
          q: queryDebounced,
          isActive: status,
          hasEntry,
          isFeeActive: hasFee,
          companyId,
        });

      setConditions(paymentConditions.rows);
      setTotalPages(Math.ceil(paymentConditions.count / pageLimit));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as condições de pagamento. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateChange = async (cond) => {
    try {
      await paymentConditionsRepository.update(cond.original.id, {
        isActive: !cond.original.IsActive,
      });
      loadPaymentConditions();
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao atualizar a condição de pagamento. Por favor, tente novamente'
      );
    }
  };

  const isDefaultCashPaymentCondition = (condition) =>
    condition.Description === 'À vista' &&
    condition.daysBetweenInstallment === '30' &&
    !condition.hasEntry;

  const handleModal = async (id) => {
    setId(id);
    setModal(true);
  };

  const closeModal = () => {
    setId(null);
    setModal(false);
  };

  useEffect(() => {
    console.log(modal);
  }, [modal]);

  return (
    <Card
      content={
        <div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              gap: '15px',
              marginBottom: '10px',
              marginTop: '10px',
            }}
          >
            <div className="">
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                style={{
                  height: '35px',
                }}
                className="btn btn-sucesso"
                onClick={
                  // !!companyId && newSale
                  //   ? () => setModal(true)
                  //   : !companyId
                  //   ? () => setNoCompanyModal(true)
                  () => history.push(constants.ROUTES.PAYMENT_CONDITION)
                }
              >
                + Nova Condição
              </button>
            </div>
            <div
              style={{
                height: '35px',
              }}
              id="review-search"
            >
              <SearchInput
                placeholder="Pesquisar por Descrição"
                value={query}
                onChange={(e) => handleChangeQuery(e.target.value)}
              />
            </div>
            <div>
              <fieldset
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '35px',
                }}
              >
                <label className="status-label">Status:</label>
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="form-control foco-input"
                >
                  <option value="">Ambos</option>
                  <option value={true}>Ativo</option>
                  <option value={false}>Inativo</option>
                </select>
              </fieldset>
            </div>
            <div>
              <fieldset
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '35px',
                }}
              >
                <label
                  style={{
                    whiteSpace: 'nowrap',
                    width: '120px',
                  }}
                  className="status-label"
                >
                  Possui Entrada?:
                </label>
                <select
                  value={hasEntry}
                  onChange={(e) => setHasEntry(e.target.value)}
                  className="form-control foco-input"
                >
                  <option value="">Ambos</option>
                  <option value={true}>Ativo</option>
                  <option value={false}>Inativo</option>
                </select>
              </fieldset>
            </div>
            <div>
              <fieldset
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '35px',
                }}
              >
                <label
                  style={{
                    whiteSpace: 'nowrap',
                    width: '100px',
                  }}
                  className="status-label"
                >
                  Possui Juros?:
                </label>
                <select
                  value={hasFee}
                  onChange={(e) => setHasFee(e.target.value)}
                  className="form-control foco-input"
                >
                  <option value="">Ambos</option>
                  <option value={true}>Ativo</option>
                  <option value={false}>Inativo</option>
                </select>
              </fieldset>
            </div>
          </div>
          <div>
            <ReactTable
              style={{
                width: '1020px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              data={conditions}
              columns={[
                {
                  Header: 'Descrição',
                  accessor: 'Description',
                  headerClassName: 'text-left',
                  width: 200,
                },
                {
                  Header: 'Possui Entrada?',
                  accessor: 'hasEntry',
                  headerClassName: 'text-left',
                  width: 150,
                  Cell: (row) => (
                    <span>
                      {row.original.hasEntry === true ? 'Sim' : 'Não'}
                    </span>
                  ),
                },
                {
                  Header: 'Dias Entre Parcelas',
                  accessor: 'daysBetweenInstallment',
                  headerClassName: 'text-left',
                  width: 150,
                },
                {
                  Header: 'Juros (%)',
                  accessor: 'feePercent',
                  headerClassName: 'text-left',
                  width: 100,
                  Cell: (props) => {
                    if (props.value) {
                      return percentage(props.value);
                    }

                    return '';
                  },
                },
                {
                  Header: 'Valor mínimo',
                  accessor: 'minValue',
                  headerClassName: 'text-left',
                  width: 130,
                  Cell: (props) => {
                    if (props.value) {
                      return currency(props.value);
                    }

                    return '';
                  },
                },
                {
                  Header: 'Sugestão',
                  accessor: 'showAsSuggestion',
                  width: 100,
                  Cell: (props) => {
                    return (
                      <button
                        className="admin-custom-label"
                        style={{
                          width: '70%',
                          background: !!props.value ? '#5cb85c' : '#d9534f',
                        }}
                      >
                        {!!props.value ? 'Sim' : 'Não'}
                      </button>
                    );
                  },
                },
                {
                  Header: 'Status',
                  accessor: 'IsActive',
                  headerClassName: 'text-left',
                  width: 100,
                  Cell: (props) => (
                    <div>
                      <Toggle
                        checked={conditions[props.index].IsActive}
                        name="ativo"
                        style={{ position: 'fixed' }}
                        value={String(conditions[props.index].IsActive)}
                        onClick={() => handleUpdateChange(props)}
                        readOnly
                      />
                    </div>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  headerClassName: 'text-left',
                  filterable: false,
                  className: 'texto',
                  width: 80,
                  Cell: (props) =>
                    !!companyId ? (
                      isDefaultCashPaymentCondition(props.original) ? (
                        <FontAwesomeIcon
                          title="Não é possível alterar a condição à vista"
                          cursor="not-allowed"
                          style={{
                            height: '1.5em',
                            width: '1.5em',
                            color: 'black',
                          }}
                          icon={faEdit}
                          onClick={() =>
                            toastr.warning(
                              'Não é possível alterar a condição à vista.'
                            )
                          }
                        />
                      ) : !!companyId && newSale ? (
                        <Link
                          to={{ pathname: `payment-condition#${props.value}` }}
                        >
                          <FontAwesomeIcon
                            title="Editar"
                            cursor="pointer"
                            style={{
                              height: '1.5em',
                              width: '1.5em',
                              color: 'black',
                            }}
                            icon={faEdit}
                          />
                        </Link>
                      ) : (
                        <Link
                          to={{ pathname: `payment-condition#${props.value}` }}
                        >
                          <FontAwesomeIcon
                            title="Editar"
                            cursor="pointer"
                            style={{
                              height: '1.5em',
                              width: '1.5em',
                              color: 'black',
                            }}
                            icon={faEdit}
                          />
                        </Link>
                      )
                    ) : (
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="false"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setNoCompanyModal(true)}
                      />
                    ),
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pages={totalPages}
              page={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
              manual
              onPageSizeChange={(size) => setPageLimit(size)}
              pageSizeOptions={[10, 20, 30, 40, 50]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
          <Modal
            dialogClassName="modal-formProduto"
            show={modal}
            onHide={() => {
              setModal(false);
              closeModal();
            }}
            animation={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <strong>{currentBrandingName}</strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body id="modal-formProduto-body">
              <RegisterPaymentCondition
                handleParentModalClose={() => closeModal()}
                hasParentModal={true}
                shouldTriggerReloadList={true}
                reloadConditionsList={() => loadPaymentConditions()}
                conditionId={id}
              />
            </Modal.Body>
          </Modal>
        </div>
      }
    />
  );
};

export default withRouter(HomePaymentCondition);
