import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTable from 'react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faWallet } from '@fortawesome/free-solid-svg-icons';
import Card from 'components/Card/Card.jsx';
import Button from 'client/components/CustomButton/Button.jsx';

import { useAuth } from 'contexts/auth';
import useFilters from '../../../hooks/useFilters';
import constants from '../../../utils/constants';
import cashierBankRepository from '../../../repositories/CashierBank';
import { encrypt } from '../../components/ToNormalize/ToNormalize';
import BalanceModal from './components/BalanceModal';

const HomeCashierBank = () => {
  const [cashierBanks, setCachierBanks] = useState([]);
  const [cashierBanksTypes, setCachierBanksTypes] = useState([]);
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);
  const [cashierBankId, setCashierBankId] = useState();
  const [loading, setLoading] = useState(false);

  const { queryFilter, statusFilter, typeFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const { status, setStatus, filterByStatus } = statusFilter;
  const { type, setType, filterByType } = typeFilter;

  const { companyId } = useAuth();
  const history = useHistory();

  useEffect(() => {
    getCashierBankTypes();
    getCashierBanks();
  }, []);

  const getCashierBanks = async () => {
    setLoading(true);
    try {
      await cashierBankRepository
        .getCashierBanksByCompany(companyId)
        .then((resp) => setCachierBanks(resp));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getCashierBankTypes = async () => {
    try {
      await cashierBankRepository
        .getCashierBankTypes()
        .then((resp) => setCachierBanksTypes(resp));
    } catch (err) {
      console.error(err);
    }
  };

  const handleFilters = (cashierBank) => {
    const querySearch = [
      cashierBank['description'],
      cashierBank['agency'],
      cashierBank['checkingAccount'],
    ];
    return (
      filterByQuery(querySearch) &&
      filterByStatus('isActive', cashierBank) &&
      filterByType('cashierBankTypeId', cashierBank)
    );
  };

  const handleEdit = async (value) => {
    const hash = await encrypt(String(value), '@OS-dig:CashierBankId');
    history.push(`${constants.ROUTES.EDIT_CASHIER_BANK}?id=${hash}`);
  };

  const handleBalanceModal = async (value) => {
    setIsBalanceModalOpen(true);
    setCashierBankId(value);
  };

  return (
    <>
      <Card
        content={
          <div>
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              id="reviewHeader"
              style={{ marginTop: '10px' }}
            >
              <div>
                <button
                  className="btn btn-sucesso"
                  onClick={() => history.push(constants.ROUTES.CASHIER_BANK)}
                >
                  + Novo Banco/Caixa Interno
                </button>
              </div>
              <div
                className="col-xs-12 col-sm-8 col-md-5 col-lg-5"
                id="review-search"
              >
                <i id="iconepesquisar" className="fa fa-search"></i>
                <input
                  type="text"
                  style={{ paddingLeft: '30px' }}
                  className="form-control foco-input"
                  placeholder="Pesquisa por Descrição, Agência ou Conta Corrente"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <strong>Tipo: </strong>
                </span>
                <select
                  className="form-control foco-input"
                  name="searchStatus"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  style={{ marginLeft: '10px' }}
                >
                  <option value="">Ambos</option>
                  {cashierBanksTypes.map(({ id, description }) => (
                    <option key={id} value={id}>
                      {description}
                    </option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: '10px',
                }}
              >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <strong>Status: </strong>
                </span>
                <select
                  className="form-control foco-input"
                  name="searchStatus"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  style={{ marginLeft: '10px' }}
                >
                  <option value="">Ambos</option>
                  <option value={true}>Ativo</option>
                  <option value={false}>Inativo</option>
                </select>
              </div>
            </div>
            <div>
              <ReactTable
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
                data={cashierBanks.filter(handleFilters)}
                columns={[
                  {
                    Header: 'Descrição',
                    accessor: 'description',
                    headerClassName: 'text-left',
                    width: 300,
                  },
                  {
                    Header: 'Tipo',
                    accessor: 'CashierBankType.description',
                    headerClassName: 'text-left',
                    width: 110,
                    Cell: (props) => (
                      <Button
                        id="botaoReactTable"
                        style={{
                          zIndex: '0',
                          width: '100%',
                          height: '100%',
                          backgroundColor:
                            props.original.CashierBankType.id === 1
                              ? '#428bca'
                              : '#5cb85c',
                        }}
                      >
                        {props.value}
                      </Button>
                    ),
                  },
                  {
                    Header: 'Banco',
                    accessor: 'Bank',
                    headerClassName: 'text-left',
                    Cell: (props) =>
                      props.value &&
                      `${props.value?.code} - ${props.value?.name}`,
                  },
                  {
                    Header: 'Agência',
                    accessor: 'agency',
                    headerClassName: 'text-left',
                  },
                  {
                    Header: 'Conta Corrente',
                    accessor: 'checkingAccount',
                    headerClassName: 'text-left',
                  },
                  {
                    Header: 'Status',
                    accessor: 'isActive',
                    headerClassName: 'text-left',
                    width: 100,
                    Cell: (props) => (
                      <Button
                        id="botaoReactTable"
                        style={{
                          zIndex: '0',
                          width: '100%',
                          height: '100%',
                          backgroundColor: props.value ? '#46a546' : '#bd362f',
                        }}
                      >
                        {props.value ? 'Ativo' : 'Inativo'}
                      </Button>
                    ),
                  },
                  {
                    Header: 'Ações',
                    accessor: 'Actions',
                    headerClassName: 'text-left',
                    filterable: false,
                    width: 80,
                    Cell: (props) => (
                      <>
                        <FontAwesomeIcon
                          title="Editar"
                          cursor="pointer"
                          style={{
                            height: '1.5em',
                            width: '1.5em',
                            color: 'black',
                          }}
                          icon={faEdit}
                          onClick={() => handleEdit(props.original.id)}
                        />
                        <FontAwesomeIcon
                          title="Visualizar Saldo"
                          cursor="pointer"
                          style={{
                            marginLeft: 3,
                            height: '1.5em',
                            width: '1.5em',
                            color: '#444444',
                          }}
                          icon={faWallet}
                          onClick={() => handleBalanceModal(props.original.id)}
                        />
                      </>
                    ),
                  },
                ]}
                defaultPageSize={10}
                loading={loading}
                showPagination={true}
                sortable={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultSorted={[
                  {
                    id: 'description',
                    desc: false,
                  },
                ]}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Não há informação"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
              />
            </div>
          </div>
        }
      />

      {isBalanceModalOpen && (
        <BalanceModal
          isOpen={isBalanceModalOpen}
          handleClose={() => setIsBalanceModalOpen(false)}
          cashierBankId={cashierBankId}
        />
      )}
    </>
  );
};

export default HomeCashierBank;
