import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxOpen,
  faEdit,
  faFileUpload,
} from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';
import DownloadXlsButton from '../../../components/DownloadXlsButton';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import Card from 'components/Card/Card.jsx';
import { getProvidersToExport } from './excelHelpers';
import { xlsColumns } from './excelHelpers';
import Button from 'client/components/CustomButton/Button.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import {
  cnpjMask,
  cpfMask,
  phoneMask,
} from 'client/components/ToNormalize/ToNormalize';
import providersRepository from '../../../repositories/Providers';
import useFilters from '../../../hooks/useFilters';
import { useAuth } from '../../../contexts/auth';
import constants from '../../../utils/constants';
import { format } from 'date-fns';
import { usePlanSignatureContext } from '../../../contexts/plan-signature';
import ImportFromXlsModal from 'client/components/ImportFromXlsModal';

import './styles.css';

const HomeProviders = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [providers, setProviders] = useState([]);
  const [providersToExport, setProvidersToExport] = useState([]);
  const [isImportFromXlsModalOpen, setIsImportFromXlsModalOpen] =
    useState(false);

  const { isPlanFree, isPlanStart } = usePlanSignatureContext();

  const { companyId } = useAuth();
  const { queryFilter, statusFilter, typeFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const { type, setType, filterByType } = typeFilter;
  const { status, setStatus, filterByStatus } = statusFilter;

  useEffect(() => {
    if (!!companyId) {
      loadProviders();
    }
  }, []);

  useEffect(() => {
    const providersInfoToExport = getProvidersToExport(providers);
    setProvidersToExport(providersInfoToExport);
  }, [providers, query, status]);

  const loadProviders = async () => {
    setLoading(true);
    try {
      const companyProviders = await providersRepository.getAllByCompany(
        companyId
      );

      const serializedProviders = companyProviders.map((provider) => {
        return {
          ...provider,
          Phone: provider.Phones[0]
            ? provider.Phones[0].Number_Phone1
              ? provider.Phones[0].Number_Phone1
              : provider.Phones[0].Number_Phone2
            : '',
        };
      });

      setProviders(serializedProviders);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os fornecedores. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (provider) => {
    const searchQuery = [
      provider['companyName'],
      provider['tradingName'],
      provider['cpfCnpj'],
    ];
    return (
      filterByQuery(searchQuery) &&
      filterByStatus('isActive', provider) &&
      filterByType('type', provider)
    );
  };

  const filteredProviders = providers.filter(handleFilters);
  const filteredProvidersExport = providersToExport.filter(handleFilters);

  return (
    <>
      <Card
        content={
          <div>
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '10px',
              }}
            >
              <div className="providers-direction-row">
                <div>
                  <ModalAlertCompany
                    show={noCompanyModal}
                    onHide={() => setNoCompanyModal(false)}
                  />
                  <button
                    id="btn-nova-revisao"
                    className="btn btn-sucesso"
                    onClick={() =>
                      !!companyId
                        ? history.push(constants.ROUTES.PROVIDER)
                        : setNoCompanyModal(true)
                    }
                  >
                    + Novo Fornecedor
                  </button>
                </div>
                <div>
                  <div>
                    <i
                      id="iconepesquisar"
                      style={{ cursor: 'pointer' }}
                      className="fa fa-search"
                    />
                    <input
                      type="text"
                      style={{ paddingLeft: '9%' }}
                      className="form-control"
                      id="input-style"
                      placeholder="Pesquisa por Nome Fantasia, CPF/CNPJ ou Razão Social"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="providers-direction-row">
                <div style={{ display: 'flex' }}>
                  <label>
                    <strong>Status:</strong>
                  </label>
                  <select
                    className="form-control foco-input"
                    onChange={(event) => setStatus(event.target.value)}
                    value={status}
                  >
                    <option value="">Ambos</option>
                    <option value="true">Ativo</option>
                    <option value="false">Inativo</option>
                  </select>
                </div>
                <div style={{ display: 'flex' }}>
                  <label>
                    <strong>Tipo:</strong>
                  </label>
                  <select
                    className="form-control foco-input"
                    onChange={(event) => setType(event.target.value)}
                    value={type}
                  >
                    <option value="">Ambos</option>
                    <option value="Juridica">Jurídica</option>
                    <option value="Fisica">Física</option>
                  </select>
                </div>
                <div style={{ marginLeft: '10px' }}>
                  <DownloadXlsButton
                    archiveName={`Fornecedores - ${format(
                      new Date(),
                      'dd-MM-yyyy-HH-mm'
                    )}`}
                    data={filteredProvidersExport}
                    className="btn btn-export"
                    disabled={loading || !filteredProviders.length}
                    columns={xlsColumns}
                  >
                    <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                    Exportar .xls
                  </DownloadXlsButton>
                </div>

                {!isPlanFree && !isPlanStart ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: '0.6em',
                    }}
                  >
                    <button
                      className="btn btn-export-suggestion"
                      id="button-style"
                      onClick={() => setIsImportFromXlsModalOpen(true)}
                      title="Importar Produtos"
                    >
                      <FontAwesomeIcon
                        cursor="pointer"
                        style={{
                          marginRight: '10px',
                        }}
                        icon={faFileUpload}
                      />
                      Importar Fornecedores
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div>
              <ReactTable
                style={{ fontWeight: 'bold', textAlign: 'center' }}
                data={filteredProviders}
                columns={[
                  {
                    Header: 'CPF/CNPJ',
                    accessor: 'cpfCnpj',
                    headerClassName: 'text-center',
                    className: 'texto',
                    Cell: (props) => (
                      <>
                        {!!props.value
                          ? props.original.type === 'Juridica'
                            ? cnpjMask(props.value)
                            : cpfMask(props.value)
                          : null}
                      </>
                    ),
                  },
                  {
                    Header: 'Tipo',
                    accessor: 'type',
                    headerClassName: 'text-center',
                    Cell: (props) => (
                      <Button
                        id="botaoReactTable"
                        style={{
                          backgroundColor:
                            props.value === 'Fisica' ? '#0d45a4' : '#631678',
                        }}
                      >
                        {props.value}
                      </Button>
                    ),
                  },
                  {
                    Header: 'Nome/Razão Social',
                    accessor: 'companyName',
                    headerClassName: 'text-center',
                    className: 'texto',
                  },
                  {
                    Header: 'Nome Fantasia',
                    accessor: 'tradingName',
                    headerClassName: 'text-center',
                    className: 'texto',
                  },
                  {
                    Header: 'Telefone',
                    accessor: 'Phone',
                    headerClassName: 'text-center',
                    className: 'texto',
                    Cell: (props) => (
                      <>{props.value !== '' ? phoneMask(props.value) : ''}</>
                    ),
                  },
                  {
                    Header: 'Status',
                    accessor: 'isActive',
                    headerClassName: 'text-center',
                    width: 100,
                    className: 'texto',
                    Cell: (props) => (
                      <Button
                        id="botaoReactTable"
                        style={{
                          backgroundColor: !!props.value ? 'green' : 'red',
                        }}
                      >
                        {!!props.value ? 'Ativo' : 'Inativo'}
                      </Button>
                    ),
                  },
                  {
                    Header: 'Ações',
                    accessor: 'id',
                    headerClassName: 'text-left',
                    filterable: false,
                    className: 'texto',
                    width: 100,
                    Cell: (props) => (
                      <div className="table-actions">
                        <Link to={{ pathname: `provider#${props.value}` }}>
                          <FontAwesomeIcon
                            title="Editar"
                            cursor="pointer"
                            icon={faEdit}
                          />
                        </Link>
                        <Link
                          to={{ pathname: `provider-products#${props.value}` }}
                        >
                          <FontAwesomeIcon
                            title="Produtos fornecedores"
                            cursor="pointer"
                            icon={faBoxOpen}
                          />
                        </Link>
                      </div>
                    ),
                  },
                ]}
                defaultPageSize={10}
                loading={loading}
                showPagination={true}
                sortable={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultSorted={[
                  {
                    id: 'tradingName',
                    desc: false,
                  },
                ]}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum Fornecedor encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
              />
            </div>
          </div>
        }
      />
      <ImportFromXlsModal
        show={isImportFromXlsModalOpen}
        onCancel={() => setIsImportFromXlsModalOpen(false)}
        importType="providers"
        loadItems={loadProviders}
      />
    </>
  );
};

export default withRouter(HomeProviders);
