import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';

import useDebounce from 'hooks/useDebounce';

import TradeRepresentativeTable from './TradeRepresentativeTable';
import SearchInput from 'components/SearchInput';
import DownloadXlsButton from 'components/DownloadXlsButton';

import TradeRepresentativeRepository from 'repositories/TradeRepresentative';

import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

export default function TradeRepresentativeMain() {
  const [loading, setLoading] = useState(false);
  const [tradeRepresentatives, setTradeRepresentatives] = useState([]);
  const { companyId } = useAuth();

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  // consts
  const [representativeQuery, setRepresentativeQuery] = useState('');
  const [representativeStatusQuery, setRepresentativeStatusQuery] =
    useState('');
  const [representativeTypeQuery, setRepresentativeTypeQuery] = useState('');
  const history = useHistory();

  // debounced states
  const [representativeQueryDebounced, setRepresentativeQueryDebounced] =
    useState('');
  const [
    representativeStatusQueryDebounced,
    setRepresentativeStatusQueryDebounced,
  ] = useState('');
  const [
    representativeTypeQueryDebounced,
    setRepresentativeTypeQueryDebounced,
  ] = useState('');

  // debounce use
  const debouncedSaveQuery = useDebounce(setRepresentativeQueryDebounced, 500);
  const debouncedSaveStatus = useDebounce(
    setRepresentativeStatusQueryDebounced,
    500
  );
  const debouncedSaveTypeQuery = useDebounce(
    setRepresentativeTypeQueryDebounced,
    500
  );

  useEffect(() => {
    if (!!companyId) {
      loadRepresentatives();
    }
  }, [
    companyId,
    currentPage,
    pageLimit,
    representativeQueryDebounced,
    representativeStatusQueryDebounced,
    representativeTypeQueryDebounced,
  ]);

  const handleChangeRepresentativeQuery = (e) => {
    setRepresentativeQuery(e.target.value);
    debouncedSaveQuery(e.target.value);
  };

  const handleChangeRepresentativeTypeQuery = (e) => {
    setRepresentativeTypeQuery(e.target.value);
    debouncedSaveTypeQuery(e.target.value);
  };

  const handleChangeRepresentativeStatusQuery = (e) => {
    setRepresentativeStatusQuery(e.target.value);
    debouncedSaveStatus(e.target.value);
  };

  const loadRepresentatives = async () => {
    setLoading(true);

    try {
      const representatives = await TradeRepresentativeRepository.list(
        companyId,
        {
          limit: pageLimit,
          page: currentPage + 1,
          type: representativeTypeQueryDebounced,
          status: representativeStatusQueryDebounced,
          query: representativeQueryDebounced,
        }
      );

      setTradeRepresentatives(representatives.tradeRepresentativeList);
      setTotalPages(Math.ceil(representatives.count / pageLimit));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os representantes. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="trade-representatives__wrapper">
      <section className="trade-representatives__filter-container">
        <button
          className="btn btn-sucesso new-boleto-flex-config"
          onClick={() => history.push('trade-representative')}
        >
          + Novo Representante
        </button>
        <div className="search-input-wrapper">
          <SearchInput
            placeholder="Pesquisa por CPF/CNPJ, Nome/Razão Social e Nome Fantasia"
            value={representativeQuery}
            onChange={handleChangeRepresentativeQuery}
          />
        </div>
        <fieldset>
          <label style={{ width: '75px' }}>Status:</label>
          <select
            value={representativeStatusQuery}
            onChange={handleChangeRepresentativeStatusQuery}
            className="form-control foco-input"
          >
            <option value="">Ambos</option>
            <option value="1">Ativo</option>
            <option value="0">Inativo</option>
          </select>
        </fieldset>
        <fieldset>
          <label style={{ width: '75px' }}>Tipo:</label>
          <select
            value={representativeTypeQuery}
            onChange={handleChangeRepresentativeTypeQuery}
            className="form-control foco-input"
          >
            <option value="">Ambos</option>
            <option value="Fisica">Físico</option>
            <option value="Juridica">Jurídico</option>
          </select>
        </fieldset>
        <div
          style={{
            marginLeft: '10px',
          }}
        >
          <DownloadXlsButton
            archiveName="Representantes"
            data={tradeRepresentatives}
            className="btn btn-export"
            disabled={tradeRepresentatives.length === 0}
            columns={[
              {
                Header: 'CPF/CNPJ',
                accessor: 'cpfCnpj',
              },
              {
                Header: 'Tipo',
                accessor: 'type',
              },
              {
                Header: 'Nome/Razão Social',
                accessor: 'companyName',
              },
              {
                Header: 'Nome Fantasia',
                accessor: 'tradingName',
              },
              {
                Header: 'Telefone',
                accessor: 'Phones[0].Number_Phone1',
              },
              {
                Header: 'Status',
                accessor: 'isActive',
              },
            ]}
          >
            <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} /> Exportar
            .xls
          </DownloadXlsButton>
        </div>
      </section>

      {/* Representative Table */}
      <section>
        <TradeRepresentativeTable
          tradeRepresentatives={tradeRepresentatives}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          loading={loading}
        />
      </section>
    </main>
  );
}
