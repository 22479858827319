import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { cestMask } from 'client/components/ToNormalize/ToNormalize';
import { toastr } from 'react-redux-toastr';
import ReactTable from 'react-table';
import InputMask from 'react-input-mask';
import {
  faEdit,
  faTimes,
  faCheckSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'components/Card/Card.jsx';
import Button from 'client/components/CustomButton/Button.jsx';

import NCMNBSRepository from '../../../repositories/NCMNBS';
import { useAuth } from 'contexts/auth';
import useDebounce from 'hooks/useDebounce';

const HomeNCMNBS = () => {
  const [loading, setLoading] = useState(false);
  const [NCMNBS, setNCMNBS] = useState([]);
  const { companyId } = useAuth();

  const [isEditing, setIsEditing] = useState(false);
  const [cestToEditIndex, setCestToEditIndex] = useState(null);
  const [cestToUpdate, setCestToUpdate] = useState(null);

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  // consts
  const [ncmQuery, setNcmQuery] = useState('');
  const [ncmTypeQuery, setNcmTypeQuery] = useState('');

  // debounced states
  const [ncmQueryDebounced, setNcmQueryDebounced] = useState('');
  const [ncmTypeQueryDebounced, setNcmTypeQueryDebounced] = useState('');

  // debounce use
  const debouncedSaveQuery = useDebounce(setNcmQueryDebounced, 500);
  const debouncedSaveTypeQuery = useDebounce(setNcmTypeQueryDebounced, 500);

  const { control, setValue, getValues, reset } = useForm({
    defaultValues: {
      newCEST: '',
    },
  });

  useEffect(() => {
    if (!!companyId) {
      loadNCMNBS();
    }
  }, [
    companyId,
    currentPage,
    pageLimit,
    ncmQueryDebounced,
    ncmTypeQueryDebounced,
  ]);

  useEffect(() => {
    if (cestToEditIndex !== null) {
      setValue('newCEST', cestToUpdate.CEST);
    }
  }, [cestToEditIndex]);

  async function handleUpdateCest() {
    // const cestUpdate = NCMNBS[cestToEditIndex]

    const { newCEST: newValue } = getValues();

    const CEST = newValue
      ? newValue.replaceAll('.', '').replaceAll('_', '')
      : '';
    if (CEST?.length > 0 && CEST?.length < 7) {
      toastr.warning(
        'CEST inválido.',
        ' Insira um CEST válido e tente novamente'
      );
      return;
    }

    try {
      const ncmNbsId = cestToUpdate.id;

      await NCMNBSRepository.update(CEST, companyId, ncmNbsId);

      loadNCMNBS();
      setIsEditing(false);
      setCestToEditIndex(null);
    } catch (err) {
      console.log(err);
      toastr.error('Erro', 'Ocorreu um erro. Tente novamente!');
    }
  }

  const loadNCMNBS = async () => {
    setLoading(true);

    try {
      const NCMNBS = await NCMNBSRepository.getAll({
        companyId,
        limit: pageLimit,
        page: currentPage + 1,
        type: ncmTypeQueryDebounced,
        query: ncmQueryDebounced,
      });

      setNCMNBS(NCMNBS.ncmNbs);
      setTotalPages(Math.ceil(NCMNBS.count / pageLimit));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os NCM e NBS. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChangeNcmQuery = (e) => {
    setNcmQuery(e.target.value);
    debouncedSaveQuery(e.target.value);
  };

  const handleChangeNcmTypeQuery = (e) => {
    setNcmTypeQuery(e.target.value);
    debouncedSaveTypeQuery(e.target.value);
  };

  return (
    <Card
      content={
        <div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            id="reviewHeader"
            style={{ marginTop: '10px' }}
          >
            <div
              className="col-xs-12 col-sm-8 col-md-5 col-lg-5"
              id="input-search"
            >
              <i id="iconepesquisar" className="fa fa-search"></i>
              <input
                type="text"
                style={{ paddingLeft: '30px' }}
                className="form-control foco-input"
                placeholder="Pesquisar por NCM/NBS, Descrição ou CEST"
                value={ncmQuery}
                onChange={handleChangeNcmQuery}
              />
            </div>
            <div
              className="col-xs-12 col-sm-8 col-md-2 col-lg-2"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <strong>Tipo: </strong>
              </span>
              <select
                className="form-control foco-input"
                name="searchStatus"
                value={ncmTypeQuery}
                onChange={handleChangeNcmTypeQuery}
                style={{ marginLeft: '10px' }}
              >
                <option value="">Ambos</option>
                <option value="0">Produto</option>
                <option value="2">Serviço</option>
              </select>
            </div>
          </div>
          <div>
            <ReactTable
              style={{ fontWeight: 'bold', textAlign: 'center' }}
              data={NCMNBS}
              columns={[
                {
                  Header: 'Código',
                  id: 'code',
                  accessor: 'code',
                  headerClassName: 'text-left',
                  width: 100,
                },
                {
                  Header: 'Tipo',
                  accessor: 'Type',
                  headerClassName: 'text-left',
                  width: 90,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor: props.value === 0 ? 'green' : 'red',
                      }}
                    >
                      {props.value === 0 ? 'Produto' : 'Serviço'}
                    </Button>
                  ),
                },
                {
                  Header: 'Descrição',
                  accessor: 'description',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'EX',
                  accessor: 'ex',
                  headerClassName: 'text-left',
                  width: 100,
                  Cell: (props) => <>{props.value ? props.value : '---'}</>,
                },
                {
                  Header: 'CEST',
                  accessor: 'CEST',
                  headerClassName: 'text-left',
                  width: 150,
                  Cell: (props) => {
                    return (
                      <>
                        {isEditing && cestToEditIndex === props.index ? (
                          <>
                            <Controller
                              name={`newCEST`}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <InputMask
                                    // maxLength="7"
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    className="form-control"
                                    {...field}
                                    mask={'99.999.99'}
                                  />
                                );
                              }}
                            />
                          </>
                        ) : (
                          cestMask(props.value)
                        )}
                      </>
                    );
                  },
                },
                {
                  Header: '',
                  accessor: 'id',
                  resizable: false,
                  width: 70,

                  Cell: (props) => {
                    return (
                      <>
                        {isEditing && cestToEditIndex === props.index ? (
                          <>
                            <FontAwesomeIcon
                              title="Confirmar"
                              color="green"
                              icon={faCheckSquare}
                              style={{
                                height: '1.2em',
                                width: '1.2em',
                                marginBottom: -1,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                handleUpdateCest();
                              }}
                            />
                            &nbsp;
                            <FontAwesomeIcon
                              title="Cancelar Edição"
                              color="red"
                              icon={faTimes}
                              style={{
                                height: '1.2em',
                                width: '1.2em',
                                marginBottom: -1,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setCestToEditIndex(null);
                                setIsEditing(false);
                                reset();
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon
                              title="Editar CEST"
                              color="black"
                              icon={faEdit}
                              style={{
                                height: '1.2em',
                                width: '1.2em',
                                marginBottom: -1,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                reset();
                                setCestToEditIndex(props.index);
                                setCestToUpdate(props.original);
                                setIsEditing(true);
                              }}
                            />
                          </>
                        )}
                      </>
                    );
                  },
                },
              ]}
              pages={totalPages}
              page={currentPage}
              onPageChange={setCurrentPage}
              manual
              onPageSizeChange={(value) => {
                setPageLimit(value);
              }}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'Type',
                  desc: false,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </div>
      }
    />
  );
};

export default HomeNCMNBS;
