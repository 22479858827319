import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { initialize, isDirty } from 'redux-form';
import { useDispatch, connect } from 'react-redux';

import TaxData from './TaxData';
import SettingsNFe from './SettingsNFe';
import AdditionalInfo from './AdditionalInfo';
import SettingsNFCe from './SettingsNFCe';
import SettingsNFSe from './SettingsNFSe';
import HomeSat from './HomeSats';
import Button from '../../../components/CustomButton/CustomButton';
import AlertModal from '../../../../components/AlertModal';

import companiesRepository from '../../../../repositories/Companies';
import { cpfMask, cnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { useAuth } from '../../../../contexts/auth';

const FormCompanyTaxData = ({ onCancel, isAnyFormDirty }) => {
  const [isNFSePatternAvailable, setIsNFSePatternAvailable] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [fiscalDocumentType, setFiscalDocumentType] = useState('');
  const [digitalCertificateImported, setDigitalCertificateImported] =
    useState(false);

  const dispatch = useDispatch();
  const { companyId } = useAuth();

  useEffect(() => {
    loadCompanyTaxData();
  }, []);

  const loadCompanyTaxData = async () => {
    try {
      const companyTaxData = await companiesRepository.getTaxData(companyId);

      const { AdditionalInfo, SettingsNFCe, SettingsNFe, SettingsNFSe } =
        companyTaxData;
      loadTaxData(companyTaxData);

      if (SettingsNFCe) {
        loadNFCeData(SettingsNFCe);
      }
      if (SettingsNFe) {
        loadNFeData(SettingsNFe);
      }
      if (AdditionalInfo) {
        loadFormAdditionalInfo(AdditionalInfo);
      }
      if (SettingsNFSe) {
        setIsNFSePatternAvailable(true);
        loadNFSeData(SettingsNFSe);
      }
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as informações fiscais. Por favor, tente novamente'
      );
    }
  };

  const loadFormAdditionalInfo = (values) => {
    dispatch(
      initialize(
        'settingsAdditionalInfo',
        values,
        'selectedOptions',
        'description'
      )
    );
  };

  const loadTaxData = (values) => {
    const {
      TaxRegime,
      pCredSN,
      PIS,
      COFINS,
      accountingCpfCnpj,
      accountingEmail,
      tokenIBPT,
      isDigitalCertificateImported,
      digitalCertificatePassword,
      digitalCertificateExpireDate,
      printInvoicesAutomatically,
      issueInvoicesAutomatically,
      IE,
      SettingsNFCe,
    } = values;

    const data = {
      taxRegime: TaxRegime || 'Simples Nacional',
      pCredSN,
      PIS,
      IE,
      COFINS,
      accountingCpfCnpj: !accountingCpfCnpj
        ? ''
        : accountingCpfCnpj.length <= 11
        ? cpfMask(accountingCpfCnpj)
        : cnpjMask(accountingCpfCnpj),
      accountingEmail,
      tokenIBPT,
      isDigitalCertificateImported,
      digitalCertificatePassword,
      digitalCertificateExpireDate:
        digitalCertificateExpireDate === '0000-00-00'
          ? null
          : digitalCertificateExpireDate,
      printInvoicesAutomatically,
      issueInvoicesAutomatically,
      NFCePrintingTemplate: SettingsNFCe?.printingTemplate,
    };
    dispatch(initialize('settingsTaxData', data));

    setDigitalCertificateImported(isDigitalCertificateImported);
  };

  const loadNFCeData = (values) => {
    const {
      id,
      environment,
      CSC,
      CSCId,
      lastNFCeSent,
      serie,
      printingTemplate,
      eNotasId,
      satActivationCode,
      isSatCertificateImported,
      fiscalDocumentType,
    } = values;
    const data = {
      settingsNFCeId: id,
      environment,
      CSC,
      CSCId,
      lastNFCeSent: String(lastNFCeSent),
      serie,
      printingTemplate,
      eNotasId,
      satActivationCode,
      isSatCertificateImported,
      fiscalDocumentType,
    };

    setFiscalDocumentType(fiscalDocumentType);
    dispatch(initialize('settingsNFCe', data));
  };

  const loadNFeData = (values) => {
    const { id, environment, lastNFeSent, serie, printingTemplate } = values;
    const data = {
      settingsNFeId: id,
      environment,
      lastNFeSent: String(lastNFeSent),
      serie,
      printingTemplate,
    };
    dispatch(initialize('settingsNFe', data));
  };

  const loadNFSeData = (values) => {
    const {
      CMCCode,
      CNAE,
      IM,
      ISS,
      environment,
      id,
      lastNFSeSent,
      municipalTaxCode,
      printingTemplate,
      serie,
      serviceListItem,
      serviceLocationCode,
      specialTaxRegimeCode,
      userAccess,
      userPassword,
      NFSPatterns,
      taxType,
      operationType,
      NFSPatternId,
      opt_sn,
    } = values;

    const data = {
      settingsNFSeId: id,
      lastNFSeSent: String(lastNFSeSent),
      municipalTaxCode,
      CMCCode: String(CMCCode),
      CNAE,
      IM,
      ISS,
      environment,
      printingTemplate,
      serie,
      serviceListItem,
      serviceLocationCode: String(serviceLocationCode),
      specialTaxRegimeCode: String(specialTaxRegimeCode),
      userAccess,
      userPassword,
      pattern: NFSPatterns.pattern,
      taxType,
      operationType,
      NFSPatternId,
      opt_sn,
    };
    dispatch(initialize('settingsNFSe', data));
  };

  const handleCancel = () => {
    if (isAnyFormDirty) {
      return setIsCancelModalOpen(true);
    }
    onCancel();
  };

  return (
    <div id="tax-data-page">
      <TaxData onSubmit={() => {}} />
      <SettingsNFe />
      <SettingsNFCe
        fiscalDocumentType={fiscalDocumentType}
        setFiscalDocumentType={setFiscalDocumentType}
      />
      <SettingsNFSe
        onSubmit={() => {}}
        isNFSePatternAvailable={isNFSePatternAvailable}
        setIsNFSePatternAvailable={setIsNFSePatternAvailable}
      />
      <AdditionalInfo
        isDigitalCertificateImported={digitalCertificateImported}
      />
      {/* SAT COMENTADO POIS NÃO ENTRA NESSA VERSÃO */}
      {/* <HomeSat companyId={companyId} /> */}
      <footer>
        <Button bsStyle="danger" fill onClick={handleCancel}>
          Voltar
        </Button>
      </footer>
      <AlertModal
        show={isCancelModalOpen}
        message="Deseja sair sem salvar as informações ? Se sair sem salvar, deverá ser inseridas novamente posteriormente."
        title="Existem informações não salvas."
        onCancel={() => setIsCancelModalOpen(false)}
        onHide={() => setIsCancelModalOpen(false)}
        onSubmit={onCancel}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  isAnyFormDirty:
    isDirty('settingsNFSe')(state) ||
    isDirty('settingsNFCe')(state) ||
    isDirty('settingsNFe')(state) ||
    isDirty('settingsTaxData')(state),
});

export default connect(mapStateToProps)(FormCompanyTaxData);
