import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { toastr } from 'react-redux-toastr';

import { useAuth } from 'contexts/auth';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import useDebounce from 'hooks/useDebounce';

import { useRouter } from 'v2/hooks/useRouter';
import { listSalesSubtype } from 'v2/repositories/SalesSubtypeRepository';

import { DefaultContentContainer } from 'v2/components/layout';
import { SearchInput } from 'v2/components/form';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { InputSelectDefault } from 'v2/components/Input';
import { Table } from 'v2/components/Table';
import { ActiveStatusLabel } from 'v2/components/ActiveStatusLabel';

import { getBreadcrumbData, optionsIsActiveSelect } from './utils';
import { TableActions } from './TableActions';

export function RegisterSubtype() {
  const history = useHistory();
  const { navigateToClientPage } = useRouter();
  const { companyId } = useAuth();

  const [isActiveSelect, setIsActiveSelect] = useState(
    optionsIsActiveSelect[0]
  );

  const [queryInput, setQueryInput] = useState('');
  const debounceQueryInput = useDebounce(setQueryInput, 600);

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState([]);

  const [triggerFetch, setTriggerFetch] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const handleTriggerFetch = () => {
    setTriggerFetch(true);

    setTimeout(() => {
      setTriggerFetch(false);
    }, [500]);
  };

  const handleFetch = async () => {
    setIsFetching(true);

    try {
      const res = await listSalesSubtype({
        page: page + 1,
        limit,
        isActive: isActiveSelect.value,
        query: queryInput,
        companyId,
      });

      setCount(res.count);
      setRows(res.rows);
      setPages(Math.ceil(res.count / limit));
    } catch (err) {
      console.log(err);
      toastr.err(
        'Erro ao carregar os dados',
        'Por favor, tente novamente. Caso persista, contate o suporte.'
      );
    } finally {
      setIsFetching(false);
    }
  };

  const columns = [
    {
      Header: 'Descrição',
      accessor: 'description',
    },
    {
      Header: 'Status',
      accessor: 'isActive',
      width: 120,
      Cell: (props) => {
        return <ActiveStatusLabel isActive={props.value} />;
      },
    },
    {
      Header: 'Ações',
      accessor: 'id',
      width: 90,
      Cell: (props) => {
        return (
          <TableActions
            {...props.original}
            triggerFetch={handleTriggerFetch}
            isDefault={props.original.isDefault}
          />
        );
      },
    },
  ];

  useEffect(() => {
    handleFetch();
  }, [queryInput, isActiveSelect, companyId]);

  useEffect(() => {
    if (triggerFetch) {
      handleFetch();
    }
  }, [triggerFetch]);

  return (
    <DefaultContentContainer>
      <BreadCrumb {...getBreadcrumbData()} />

      <div className="content__main">
        <div className="flex gap-1">
          <button
            className="button button-green button-h35"
            onClick={() => navigateToClientPage('sale_subtype', 'form_new')}
          >
            <span>+ Novo Subtipo</span>
          </button>

          <SearchInput
            id="input__query"
            placeholder="Pesquisa por Descrição..."
            width={230}
            onChange={(e) => debounceQueryInput(e.target.value)}
          />

          <InputContainer variant="row">
            <InputLabel htmlFor="input__isActive">Status:</InputLabel>
            <InputSelectDefault
              id="input__isActive"
              width={'130px'}
              options={optionsIsActiveSelect}
              value={isActiveSelect}
              onChange={(value) => setIsActiveSelect(value)}
            />
          </InputContainer>
        </div>

        <div className="mt-2" style={{ width: '700px' }}>
          <Table
            columns={columns}
            data={rows}
            pageSize={limit}
            page={page}
            onPageChange={setPage}
            manual
            onPageSizeChange={setLimit}
            pages={pages}
            loading={isFetching}
          />
        </div>
      </div>
    </DefaultContentContainer>
  );
}
