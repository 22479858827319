import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field } from 'redux-form';
import { withRouter } from 'react-router';
import { toastr } from 'react-redux-toastr';

import Button from '../../../../client/components/CustomButton/CustomButton.jsx';
import { currencyMask } from 'utils/masks.js';
import RenderField from 'components/RenderField/index.js';
import { ModalPaymentAuthorizationCodeReview } from 'v2/components/sale/ModalPaymentAuthorizationCodeReview/ModalPaymentAuthorizationCodeReview.jsx';
import { ModalDigitalPaymentAlert } from 'v2/components/fiscal/ModalDigitalPaymentAlert/ModalDigitalPaymentAlert.jsx';
import { hasDigitalPayments } from 'v2/helpers/paymentHelpers.js';
import { getCompanyAddress } from 'v2/helpers/addressHelpers.js';
import { useAuth } from 'contexts/auth.js';

const Footer = ({ onConsultContingency, onSubmit, loading, onCancel }) => {
  const [isModalDigitalPaymentAlertOpen, setIsModalDigitalPaymentAlertOpen] =
    useState();

  const [submitAfterSaveAuthorization, setSubmitAfterSaveAuthorization] =
    useState(false);

  const [
    isModalPaymentAuthorizationCodeReviewOpen,
    setIsModalPaymentAuthorizationCodeReviewOpen,
  ] = useState(false);

  const { companyId } = useAuth();

  const {
    code,
    NFeId,
    isInvoiceIssued,
    isInvoiceIssuedInContingency,
    isInvoiceCanceled,
    isInvoiceDenied,
    saleParcels,
    saleId,
  } = useSelector((state) => state.form.NFe.values);
  const dispatch = useDispatch();

  async function handleEmitInvoice() {
    await dispatch(change('NFe', 'toEmit', true));
    onSubmit();
  }

  async function validateSubmission() {
    const address = await getCompanyAddress(companyId);

    const isCompanyFromRS = address.State === 'RS';
    const hasSale = !!saleId;

    if (isCompanyFromRS && hasSale && hasDigitalPayments(saleParcels)) {
      setSubmitAfterSaveAuthorization(true);
      setIsModalDigitalPaymentAlertOpen(true);
      return;
    }

    handleEmitInvoice();
    return;
  }

  const handleOpenModalPaymentAuthorizationCodeReview = () => {
    if (!saleId) {
      toastr.warning(
        'Função indisponível',
        'A função é habilitada apenas para notas com vendas vinculadas'
      );
      return;
    }

    setSubmitAfterSaveAuthorization(false);
    setIsModalPaymentAuthorizationCodeReviewOpen(true);
  };

  return (
    <div id="NFe-footer">
      <header>
        <strong>Nota</strong>
        <strong>{code}</strong>
      </header>
      <main>
        <fieldset>
          <section>
            <Field
              name="ShowBCICMS"
              component={RenderField}
              label="BC ICMS:"
              {...currencyMask}
              disabled
            />
          </section>
          <section>
            <Field
              name="BCICMSST"
              component={RenderField}
              label="BC ICMS ST:"
              {...currencyMask}
              disabled
            />
          </section>
          <section>
            <Field
              name="PISValue"
              component={RenderField}
              label="Valor PIS:"
              {...currencyMask}
              disabled
            />
          </section>
          <section>
            <Field
              name="freightValue"
              component={RenderField}
              label="Valor Frete:"
              {...currencyMask}
              disabled
            />
          </section>
          <section>
            <Field
              name="IPIValue"
              component={RenderField}
              label="Valor IPI:"
              {...currencyMask}
              disabled
            />
          </section>
          <section>
            <Field
              name="FCPValue"
              component={RenderField}
              label="Valor FCP:"
              {...currencyMask}
              disabled
            />
          </section>
          <section>
            <Field
              name="total"
              component={RenderField}
              label="Valor Total:"
              {...currencyMask}
              disabled
            />
          </section>
        </fieldset>

        <fieldset>
          <section>
            <Field
              name="ShowICMSValue"
              component={RenderField}
              label="Valor ICMS:"
              {...currencyMask}
              disabled
            />
          </section>
          <section>
            <Field
              name="ICMSSTValue"
              component={RenderField}
              label="Valor ICMS ST:"
              {...currencyMask}
              disabled
            />
          </section>
          <section>
            <Field
              name="COFINSValue"
              component={RenderField}
              label="Valor COFINS:"
              {...currencyMask}
              disabled
            />
          </section>
          <section>
            <Field
              name="inssuranceValue"
              component={RenderField}
              label="Valor Seguro:"
              {...currencyMask}
              disabled
            />
          </section>
          <section>
            <Field
              name="otherExpenses"
              component={RenderField}
              label="Outras Despesas:"
              {...currencyMask}
              disabled
            />
          </section>
          <section>
            <Field
              name="FCPSTValue"
              component={RenderField}
              label="Valor FCP ST:"
              {...currencyMask}
              disabled
            />
          </section>
          <section>
            <Field
              name="discountValue"
              component={RenderField}
              label="Descontos:"
              {...currencyMask}
              disabled
            />
          </section>
        </fieldset>
      </main>
      <footer>
        <div>
          <Button
            bsStyle="primary"
            fill
            onClick={onSubmit}
            disabled={
              loading ||
              isInvoiceDenied ||
              isInvoiceCanceled ||
              isInvoiceIssued ||
              isInvoiceIssuedInContingency
            }
          >
            <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
            {!NFeId ? 'Salvar' : 'Atualizar'}
          </Button>
          <Button
            bsStyle="warning"
            fill
            onClick={handleOpenModalPaymentAuthorizationCodeReview}
            disabled={
              loading ||
              isInvoiceDenied ||
              isInvoiceCanceled ||
              isInvoiceIssued ||
              isInvoiceIssuedInContingency
            }
          >
            Pagamentos
          </Button>
        </div>
        <div>
          {isInvoiceIssuedInContingency ? (
            <Button
              bsStyle="info"
              fill
              disabled={loading}
              onClick={onConsultContingency}
            >
              <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
              Consultar NF-e
            </Button>
          ) : (
            <Button
              bsStyle="info"
              fill
              disabled={
                loading ||
                isInvoiceIssued ||
                isInvoiceCanceled ||
                isInvoiceDenied
              }
              onClick={validateSubmission}
            >
              <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
              {isInvoiceIssued
                ? 'NF-e emitida'
                : isInvoiceCanceled
                ? 'NF-e Cancelada'
                : isInvoiceDenied
                ? 'NF-e Denegada'
                : 'Emitir NF-e'}
            </Button>
          )}
          <Button bsStyle="danger" fill disabled={loading} onClick={onCancel}>
            Voltar
          </Button>
        </div>
      </footer>

      <ModalDigitalPaymentAlert
        open={isModalDigitalPaymentAlertOpen}
        onClose={() => setIsModalDigitalPaymentAlertOpen(false)}
        onSendInvoice={handleEmitInvoice}
        onVerifyPayments={() =>
          setIsModalPaymentAuthorizationCodeReviewOpen(true)
        }
      />

      {saleId && isModalPaymentAuthorizationCodeReviewOpen && (
        <ModalPaymentAuthorizationCodeReview
          open={isModalPaymentAuthorizationCodeReviewOpen}
          onClose={() => setIsModalPaymentAuthorizationCodeReviewOpen(false)}
          afterSave={
            submitAfterSaveAuthorization ? handleEmitInvoice : () => null
          }
          saleId={saleId}
        />
      )}
    </div>
  );
};

export default withRouter(Footer);
