import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import HomeReviews from './Reviews'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const Reviews = () => {
  localStorage.setItem('selectedPath', 'client/Revisao')
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <BreadCrumb data={['Início', 'Cadastros', 'Produtos/Serviços', 'Revisão']} path={['home', null, null, 'Revisao']}/>
            <HomeReviews />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default Reviews;
