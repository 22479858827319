import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';
import { format } from 'date-fns';

import Card from 'components/Card/Card.jsx';
import Button from 'client/components/CustomButton/Button.jsx';
import DownloadXlsButton from '../../../components/DownloadXlsButton';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import { useAuth } from '../../../contexts/auth';
import constants from '../../../utils/constants';
import { xlsColumns, getPrismaBoxInfoToExport } from './excelHelpers';

import useDebounce from '../../../hooks/useDebounce';
import SearchInput from '../../../components/SearchInput';
import prismaboxRepository from '../../../repositories/PrismaBox';

import './styles.css';

const PrismaBox = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [prismaBox, setPrismaBox] = useState([]);
  const [pages, setPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [query, setQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [status, setStatus] = useState('');

  const [pbToExport, setPbToExport] = useState([]);

  const { companyId } = useAuth();
  const debouncedSaveQuerySearch = useDebounce(setSearchQuery, 800);

  useEffect(() => {
    loadPrismaBox({
      page: 0,
      pageSize,
      query: searchQuery,
      status,
    });
  }, [status, searchQuery]);

  useEffect(() => {
    setPbToExport(getPrismaBoxInfoToExport(prismaBox));
  }, [prismaBox]);

  async function loadPrismaBox(state) {
    setLoading(true);

    try {
      const response = await prismaboxRepository.index({
        companyId: companyId || 0,
        page: state.page + 1,
        limit: state.pageSize,
        query: state.query,
        status: state.status,
      });

      const total = response.headers['x-total-count'];

      setPages(Math.floor(total / state.pageSize) + 1);
      setPrismaBox(response.data);
    } catch (err) {
      if (!prismaBox) {
        toastr.warning(
          'Ocorreu um erro ao buscar os Prismas/Boxes. Por favor, tente novamente'
        );
      }
    } finally {
      setLoading(false);
    }
  }

  function handleChangeQuerySearch(value) {
    setQuery(value);
    debouncedSaveQuerySearch(value);
  }

  return (
    <>
      <Card
        content={
          <div>
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              id="reviewHeader"
              style={{ marginTop: '10px' }}
            >
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso"
                onClick={() =>
                  !!companyId
                    ? history.push(constants.ROUTES.NEW_PRISMA_BOX)
                    : setNoCompanyModal(true)
                }
              >
                + Novo Prisma/Box
              </button>
              <div
                className="col-xs-12 col-sm-5 col-md-5 col-lg-5"
                style={{ marginLeft: '0px' }}
              >
                <SearchInput
                  placeholder="Pesquisa por Descrição"
                  value={query}
                  onChange={(e) => handleChangeQuerySearch(e.target.value)}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <strong>Status: </strong>
                </span>
                <select
                  className="form-control foco-input"
                  name="searchStatus"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  style={{ marginLeft: '10px' }}
                >
                  <option value="">Ambos</option>
                  <option value={1}>Ativo</option>
                  <option value={0}>Inativo</option>
                </select>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: '10px',
                }}
              ></div>
              <div>
                <DownloadXlsButton
                  archiveName={`Cadastro_Box-Prisma-${format(
                    new Date(),
                    'dd/MM/yyyy'
                  )}`}
                  data={prismaBox}
                  className="btn btn-export"
                  disabled={loading || !pbToExport.length}
                  columns={xlsColumns}
                >
                  <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                  Exportar .xls
                </DownloadXlsButton>
              </div>
            </div>
            <div>
              <ReactTable
                style={{
                  width: '40%',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
                data={prismaBox}
                columns={[
                  {
                    Header: 'Descrição',
                    accessor: 'description',
                    width: 250,
                  },
                  {
                    Header: 'Status',
                    accessor: 'status',
                    Cell: (props) => (
                      <Button
                        id="botaoReactTable"
                        style={{
                          backgroundColor:
                            props.value === 'Ativo' ? 'green' : 'red',
                          width: '70%',
                        }}
                      >
                        {props.value === 'Ativo' ? 'Ativo' : 'Inativo'}
                      </Button>
                    ),
                  },
                  {
                    Header: 'Ações',
                    accessor: 'id',
                    filterable: false,
                    Cell: (props) =>
                      !!props.original.id && (
                        <Link to={{ pathname: `box-prisma#${props.value}` }}>
                          <FontAwesomeIcon
                            title="Editar prisma/box"
                            cursor="pointer"
                            style={{
                              height: '1.5em',
                              width: '1.5em',
                              color: 'black',
                            }}
                            icon={faEdit}
                          />
                        </Link>
                      ),
                  },
                ]}
                defaultPageSize={10}
                loading={loading}
                showPagination
                sortable
                showPaginationTop={false}
                showPaginationBottom
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                pages={pages}
                manual
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum Prisma/Box encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
                onFetchData={(state, _) =>
                  loadPrismaBox({
                    ...state,
                    query: searchQuery,
                    status,
                  })
                }
                onPageSizeChange={(newPageSize, _) => setPageSize(newPageSize)}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default withRouter(PrismaBox);
