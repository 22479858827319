import React from "react";
import { Grid, Row, Col } from "react-bootstrap";

import TextForInvoices from './TextForInvoices'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const Texts = () => {
    localStorage.setItem('selectedPath', 'client/texts-for-invoices')
    return (
        <div className="content">
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <BreadCrumb data={['Início', 'Cadastros', 'Fiscal', 'Texto Para Notas Fiscais']} path={['home', null, null, 'texts-for-invoices']} />
                        <TextForInvoices />
                    </Col>
                </Row>
            </Grid>
        </div>
    );
}

export default Texts;
