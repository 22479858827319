import React from 'react'
import { Modal, Col } from 'react-bootstrap'
import Button from '../../../components/CustomButton/CustomButton'

import { reduxForm, Field } from 'redux-form'
import { useSelector } from 'react-redux'

import TextArea from '../../Clients/NewClient/FormClient/components/TextArea'

import { currency } from '../../../components/ToNormalize/ToNormalize'

function FormCancelInvoiceModal({
  onCancel,
  handleSubmit,
  loading,
  nfceSelected,
}) {
  const {
    cancelInvoice: {
      values: { justification },
    },
  } = useSelector((state) => state.form)

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Header closeButton onHide={onCancel}>
        <Modal.Title>
          <strong>Cancelamento de {nfceSelected.type}</strong>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <span
              style={{
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              {nfceSelected.type}:{' '}
              <span style={{ fontWeight: 'normal' }}>{nfceSelected?.code}</span>
            </span>
            <span
              style={{
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              Cliente:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {nfceSelected?.clientName}
              </span>
            </span>
            <span
              style={{
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              Valor:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {currency(nfceSelected?.totalValueProducts || 0)}
              </span>
            </span>
          </Col>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col style={{ marginBottom: 5 }}>
          <label>Informe o motivo do cancelamento abaixo:</label>
          <span
            style={{
              position: 'absolute',
              right: 15,
              top: 30,
              color: justification?.length >= 15 ? 'green' : 'red',
            }}
          >
            {justification?.length || 0} caracteres
          </span>
          <Field
            name="justification"
            component={TextArea}
            placeholder="O motivo deverá ter pelo menos 15 caracteres"
          />
        </Col>
        <Col style={{ textAlign: 'justify' }}>
          <span
            style={{
              fontSize: 12,
              fontWeight: 'bold',
              color: 'red',
            }}
          >
            {nfceSelected.type === 'NFC-e'
              ? 'O prazo de cancelamento é de 30 minutos a partir do envio da nota. Alguns estados possuem prazos de cancelamento de 24 horas. Valide com sua contabilidade o prazo máximo de cancelamento.'
              : 'O prazo de cancelamento é de 24 horas corridas a partir do envio da nota. Após o prazo, o cancelamento poderá ser aceito passível de multa ou poderá não ser aceito. As decisões ficam a cargo da SEFAZ.'}
          </span>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button bsStyle="danger" disabled={loading} onClick={onCancel} fill>
            Voltar
          </Button>
          <Button
            bsStyle="info"
            disabled={loading || !justification || justification?.length < 15}
            fill
            type="submit"
          >
            Cancelar {nfceSelected.type}{' '}
            <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
          </Button>
        </div>
      </Modal.Footer>
    </form>
  )
}

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'cancelInvoice',
})(FormCancelInvoiceModal)
