import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import HomeBrands from './Brands'
import BreadCrumb from 'components/BreadCrumb/BreadCrumb'

const Brands = () => {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <BreadCrumb 
              data={['Início', 'Cadastros', 'Produtos/Serviços', 'Marcas']}
              path={['home', null, null, 'brands']}
            />
            <HomeBrands />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default Brands