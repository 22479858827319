import React, { useState, useEffect, useCallback } from 'react'
import ReactTable from 'react-table'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { toastr } from 'react-redux-toastr'
import { Modal } from 'react-bootstrap'

import Button from 'client/components/CustomButton/Button.jsx'
import CustomButton from 'client/components/CustomButton/CustomButton.jsx'
import Card from 'components/Card/Card.jsx'
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx'
import SupportButton from '../../components/SupportButton'
import useFilters from '../../../hooks/useFilters'

import './styles.css'

import signaturesRepository from '../../../repositories/Signatures'
import companiesRepository from '../../../repositories/Companies'
import employeesRepository from '../../../repositories/Employees'
import positionsRepository from '../../../repositories/Positions'
import FormUser from '../MyUsers/NewUser/BundleFormUsuario'

import constants from '../../../utils/constants'
import { useAuth } from '../../../contexts/auth'
import {
  encrypt,
  cpfMask,
  RgMask,
} from 'client/components/ToNormalize/ToNormalize'
import { useHistory } from 'react-router-dom'

const Employees = () => {
  const [loading, setLoading] = useState(false)
  const [noCompanyModal, setNoCompanyModal] = useState(false)
  const [isUserRegisterModalOpen, setIsUserRegisterModalOpen] = useState(false)
  const [isUserEditModalOpen, setIsUserEditModalOpen] = useState(false)
  const [isUserLimitReachedModalOpen, setIsUserLimitReachedModalOpen] =
    useState(false)

  const [employees, setEmployees] = useState([])
  const [positions, setPositions] = useState([])
  const [userId, setUserId] = useState(0)
  const [employeeId, setEmployeeId] = useState(0)

  const { companyId } = useAuth()
  const history = useHistory()

  const { queryFilter, statusFilter, typeFilter } = useFilters()
  const { query, setQuery, filterByQuery } = queryFilter
  const { status, setStatus, filterByStatus } = statusFilter
  const { type, setType, filterByType } = typeFilter

  useEffect(() => {
    if (companyId) {
      loadEmployees()
      loadPositions()
    }
  }, [companyId])

  useEffect(() => {
    if (userId > 0) {
      setIsUserEditModalOpen(true)
    }
  }, [userId])

  const loadEmployees = async () => {
    setLoading(true)
    try {
      const employees = await employeesRepository.getAllByCompany(companyId)
      setEmployees(employees.data)
    } catch (err) {
      console.log(err)
      toastr.warning(
        'Ocorreu um erro ao buscar os funcionários. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  const loadPositions = async () => {
    setLoading(true)
    try {
      const positions = await positionsRepository.getAllByCompany(companyId, {
        isActive: 1,
      })
      setPositions(positions.data)
    } catch (err) {
      console.log(err)
      toastr.warning(
        'Ocorreu um erro ao buscar os cargos. Por favor, tente novamente'
      )
    } finally {
      setLoading(false)
    }
  }

  const handleFilters = (employee) => {
    const querySearch = [employee['name'], employee['rg'], employee['cpf']]
    return (
      filterByQuery(querySearch) &&
      filterByStatus('isActive', employee) &&
      filterByType('positionId', employee)
    )
  }

  const handleEditItem = useCallback((id) => {
    const employeeIdHash = encrypt(id, '@OS-dig:employeeId')
    history.push(`/client/${constants.ROUTES.EMPLOYEE}?id=${employeeIdHash}`)
  }, [])

  const handleUserRegisterModal = (id) => {
    setEmployeeId(id)
    userRegisterValidation()
  }

  const handleUserRegisterSuccess = () => {
    setIsUserRegisterModalOpen(false)

    loadEmployees()
  }

  const handleUserEditSuccess = () => {
    setIsUserEditModalOpen(false)
    setUserId(0)

    loadEmployees()
  }

  const userRegisterValidation = async () => {
    try {
      let signatureUserQuantity, activeUsers

      const signatures = await signaturesRepository.getByCompany(companyId)

      signatures.sort((a, b) => a.id - b.id)

      if (!!signatures.length) {
        signatureUserQuantity = signatures[signatures.length - 1].usersQuantity
      }

      const users = await companiesRepository.getAllUsers(companyId)
      const usersAtivo = users.filter((user) => user.IsActive)

      activeUsers = usersAtivo.length

      if (activeUsers === signatureUserQuantity) {
        return setIsUserLimitReachedModalOpen(true)
      }

      return setIsUserRegisterModalOpen(true)
    } catch (err) {
      console.log(err)
    }
  }

  const cancelSupportModal = () => {
    setIsUserLimitReachedModalOpen(false)
  }

  return (
    <>
      <Card
        content={
          <div>
            <div
              className='col-xs-12 col-sm-12 col-md-12 col-lg-12'
              id='reviewHeader'
            >
              <div>
                <ModalAlertCompany
                  show={noCompanyModal}
                  onHide={() => setNoCompanyModal(false)}
                />
                <button
                  className='btn btn-sucesso'
                  onClick={() =>
                    companyId
                      ? history.push(constants.ROUTES.EMPLOYEE)
                      : setNoCompanyModal(true)
                  }
                >
                  + Novo Funcionário
                </button>
              </div>
              <div
                className='col-xs-12 col-sm-8 col-md-4 col-lg-4'
                id='review-search'
              >
                <i id='iconepesquisar' className='fa fa-search'></i>
                <input
                  type='text'
                  style={{ paddingLeft: '30px', width: '100%' }}
                  className='form-control foco-input'
                  placeholder='Pesquisa por Nome, CPF ou RG'
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <label>
                  <strong>Cargo:</strong>
                </label>
                <select
                  className='form-control foco-input'
                  onChange={(event) => setType(event.target.value)}
                  value={type}
                >
                  <option name='' value=''>
                    Selecione
                  </option>
                  {positions.map((e) => {
                    return (
                      <option key={e.id} value={e.id}>
                        {e.description}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div style={{ display: 'flex' }}>
                <label>
                  <strong>Status:</strong>
                </label>
                <select
                  className='form-control foco-input'
                  onChange={(event) => setStatus(event.target.value)}
                  value={status}
                >
                  <option value='true'>Ativo</option>
                  <option value='false'>Inativo</option>
                  <option value=''>Ambos</option>
                </select>
              </div>
            </div>
            <div>
              <ReactTable
                style={{ fontWeight: 'bold', textAlign: 'center' }}
                data={employees.filter(handleFilters)}
                columns={[
                  {
                    Header: 'Nome Completo',
                    accessor: 'name',
                    headerClassName: 'text-left',
                  },
                  {
                    Header: 'CPF',
                    accessor: 'cpf',
                    headerClassName: 'text-left',
                    Cell: (props) =>
                      !!props.value ? cpfMask(props.value) : '',
                  },
                  {
                    Header: 'RG',
                    accessor: 'rg',
                    headerClassName: 'text-left',
                    Cell: (props) => (!!props.value ? RgMask(props.value) : ''),
                  },
                  {
                    Header: 'Cargo',
                    accessor: 'Position.description',
                    headerClassName: 'text-left',
                  },
                  {
                    Header: 'Usuário',
                    accessor: 'User.Email',
                    headerClassName: 'text-left',
                    Cell: (props) =>
                      !!props.value ? (
                        <>
                          <div class='user-link'>
                            <a
                              onClick={() => {
                                setUserId(props.original.User.id)
                              }}
                            >
                              {props.value}
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          <div class='user-link'>
                            <a
                              onClick={() => {
                                handleUserRegisterModal(props.original.id)
                              }}
                            >
                              Adicionar Usuário
                            </a>
                          </div>
                        </>
                      ),
                  },
                  {
                    Header: 'Status',
                    accessor: 'isActive',
                    headerClassName: 'text-left',
                    width: 140,
                    Cell: (props) => (
                      <Button
                        id='botaoReactTable'
                        style={{
                          backgroundColor: props.value == 1 ? 'green' : 'red',
                        }}
                      >
                        {props.value == 1 ? 'Ativo' : 'Inativo'}
                      </Button>
                    ),
                  },
                  {
                    Header: 'Ações',
                    accessor: 'id',
                    headerClassName: 'text-left',
                    filterable: false,
                    width: 125,
                    Cell: (props) => (
                      <>
                        <FontAwesomeIcon
                          title='Edição de Funcionário'
                          cursor='pointer'
                          icon={faEdit}
                          style={{ width: '1.5em', height: '1.5em' }}
                          onClick={() => handleEditItem(props.value)}
                        />
                      </>
                    ),
                  },
                ]}
                defaultPageSize={10}
                className=''
                loading={loading}
                showPagination={true}
                sortable={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultSorted={[
                  {
                    id: 'description',
                    desc: false,
                  },
                ]}
                previousText='Anterior'
                nextText='Próximo'
                loadingText='Carregando...'
                noDataText='Não há informação'
                pageText='Página'
                ofText='de'
                rowsText='linhas'
              />
            </div>
          </div>
        }
      />

      {isUserRegisterModalOpen && (
        <Modal
          dialogClassName='modal-formProduto'
          show={isUserRegisterModalOpen}
          onHide={() => setIsUserRegisterModalOpen(false)}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Novo Usuário</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormUser
              modal
              handleModal={handleUserRegisterSuccess}
              hideModal={() => setIsUserRegisterModalOpen(false)}
              employeeId={employeeId}
            />
          </Modal.Body>
        </Modal>
      )}

      {isUserEditModalOpen && (
        <Modal
          dialogClassName='modal-formProduto'
          show={isUserEditModalOpen}
          onHide={() => setIsUserEditModalOpen(false)}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Editar Usuário</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormUser
              userId={userId}
              modal
              handleModal={handleUserEditSuccess}
              hideModal={() => setIsUserEditModalOpen(false)}
            />
          </Modal.Body>
        </Modal>
      )}

      {isUserLimitReachedModalOpen && (
        <Modal
          show={isUserLimitReachedModalOpen}
          onHide={() => setIsUserLimitReachedModalOpen(false)}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>OS Digital</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <strong>O limite de usuários foi atingido</strong>
              <p style={{ paddingTop: 10, fontSize: 14 }}>
                Se deseja cadastrar o usuário, será necessário contratar mais
                usuários. Solicite junto ao atendimento a cliente clicando no
                botão abaixo:
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <div style={{ marginRight: 10 }}>
                <CustomButton
                  bsStyle='danger'
                  style={{ width: '100px', height: '35px', padding: '5px' }}
                  onClick={cancelSupportModal}
                  fill
                >
                  Voltar
                </CustomButton>
              </div>
              <SupportButton />
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export default withRouter(Employees)
