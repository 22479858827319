import React, { useCallback, useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';

import Card from 'components/Card/Card.jsx';
import Button from 'client/components/CustomButton/Button.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import { cnpjMask } from 'client/components/ToNormalize/ToNormalize';
import intermediaryRepository from '../../../repositories/Intermediary';
import useFilters from '../../../hooks/useFilters';
import constants from '../../../utils/constants';
import { useAuth } from 'contexts/auth';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';

const Intermediary = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [intermediaries, setIntermediaries] = useState([]);

  const { companyId } = useAuth();
  const { queryFilter, statusFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const { status, setStatus, filterByStatus } = statusFilter;

  useEffect(() => {
    if (!!companyId) {
      loadIntermediaries();
    }
  }, []);

  const loadIntermediaries = async () => {
    setLoading(true);
    try {
      const { data } = await intermediaryRepository.getAllByCompany({
        companyId,
      });
      setIntermediaries(data);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os intermediadores. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFilters = (intermediaries) => {
    const searchQuery = [
      intermediaries['description'],
      intermediaries['cpfCnpj'],
    ];
    return (
      filterByQuery(searchQuery) && filterByStatus('isActive', intermediaries)
    );
  };

  const handleEdit = useCallback((id) => {
    const intermediaryIdHash = encrypt(id, '@OS-dig:intermediaryId');
    history.push(
      `/client/${constants.ROUTES.INTERMEDIARY}?id=${intermediaryIdHash}`
    );
  }, []);

  return (
    <Card
      content={
        <div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            id="reviewHeader"
            style={{ marginTop: '10px' }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                id="btn-nova-revisao"
                className="btn btn-sucesso"
                onClick={() =>
                  !!companyId
                    ? history.push(constants.ROUTES.INTERMEDIARY)
                    : setNoCompanyModal(true)
                }
              >
                + Novo Intermediador
              </button>
            </div>
            <div
              className="col-xs-12 col-sm-8 col-md-5 col-lg-5"
              id="review-search"
            >
              <i id="iconepesquisar" className="fa fa-search"></i>
              <input
                type="text"
                style={{ paddingLeft: '30px' }}
                className="form-control foco-input"
                placeholder="Pesquisa por CNPJ ou Descrição"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '10px',
              }}
            >
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <strong>Status: </strong>
              </span>
              <select
                className="form-control foco-input"
                name="searchStatus"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                style={{ marginLeft: '10px' }}
              >
                <option value="">Ambos</option>
                <option value="1">Ativo</option>
                <option value="0">Inativo</option>
              </select>
            </div>
          </div>
          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '800px',
              }}
              data={intermediaries.filter(handleFilters)}
              columns={[
                {
                  Header: 'CNPJ',
                  id: 'cpfCnpj',
                  accessor: 'cpfCnpj',
                  headerClassName: 'text-left',
                  width: 200,
                  Cell: (props) => cnpjMask(props.value || ''),
                },
                {
                  Header: 'Descrição',
                  accessor: 'description',
                  headerClassName: 'text-left',
                  width: 400,
                },
                {
                  Header: 'Status',
                  accessor: 'isActive',
                  headerClassName: 'text-left',
                  width: 115,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{ backgroundColor: props.value ? 'green' : 'red' }}
                    >
                      {props.value ? 'Ativo' : 'Inativo'}
                    </Button>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  headerClassName: 'text-left',
                  filterable: false,
                  width: 80,
                  Cell: (props) => (
                    <FontAwesomeIcon
                      title="Editar"
                      cursor="pointer"
                      style={{
                        height: '1.5em',
                        width: '1.5em',
                        color: 'black',
                      }}
                      icon={faEdit}
                      onClick={() => handleEdit(props.value)}
                    />
                  ),
                },
              ]}
              defaultPageSize={5}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'text',
                  desc: true,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </div>
      }
    />
  );
};

export default withRouter(Intermediary);
